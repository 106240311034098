import React, {useEffect, useMemo, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {AddToCalendarButton} from 'add-to-calendar-button-react'
import {Trans, useTranslation} from 'react-i18next'
import {
    changeEventModeration,
    getCurrentEventId,
    getEvent,
    requestCurrentEvent,
    setCurrentEventId
} from '../../store/eventsSlice'
import {AppDispatch} from '../../store/store'
import {
    getCurrentNetwork, getShowcaseType,
    getWalletAddress, sendRequestWithAuth,
    setModalAddShowcaseToEvent,
    setModalEditEvent,
    setModalEditString, setShowcaseType,
    setModalShowShowcases
} from '../../store/appSlice'
import {getUserTicketsByEvent, requestUserTicketsByEvent, setUserTicketsByEvent} from '../../store/ticketsSlice'
import {EventTicketItem} from '../tickets'
import {getUser} from '../../store/authSlice'
import {APP_URL, TG_AUTH_BOT, VAR_KEYS} from '../../utils/constants'
import {AddIcon, EditIcon, LoadingIcon, ShareIcon} from '../icons'
import {AlertElement, ButtonElement, CopyToClipboardBlock, TextareaElement} from '../elements'
import {getVariable, requestVariable, sendVariable, setVariable} from '../../store/variablesSlice'
import {ShowcaseRow} from './index'
import {getDateRangeString, getDateTime} from '../../utils/functions'
import {putOrganizerTitle} from '../../store/organizersSlice'

const Event = () => {
    const {t} = useTranslation()
    const {organizerUrl, eventUrl} = useParams()
    const [editingTicketDescription, setEditingTicketDescription] = useState(false)
    const [loadingTickets, setLoadingTickets] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0)
    const [ticketDescription, setTicketDescription] = useState('')
    const currentNetwork = useSelector(getCurrentNetwork)
    const currentEventId = useSelector(getCurrentEventId)
    const event = useSelector(getEvent(currentEventId))
    const eventTicketDescription = useSelector(getVariable(`${VAR_KEYS.EventTicketDescription}-${currentEventId}`))
    const showcaseType = useSelector(getShowcaseType)
    const userTickets = useSelector(getUserTicketsByEvent)
    const user = useSelector(getUser)
    const walletAddress = useSelector(getWalletAddress)
    let startDate = ''
    let startTime = ''
    let endDate = ''
    let endTime = ''
    let eventDate = ''
    if (event) {
        eventDate = getDateRangeString(event.startTime, event.endTime)
        let datetime = getDateTime(event.startTime, true)
        startDate = datetime.date
        startTime = datetime.time
        datetime = getDateTime(event.endTime, true)
        endDate = datetime.date
        endTime = datetime.time
    }

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        const timer = setInterval(() => {
            setLoadingTickets(true)
        }, 15000)
        return () => {
            clearInterval(timer)
            dispatch(setUserTicketsByEvent(null))
        }
    }, [])
    useEffect(() => {
        if (event) {
            dispatch(setShowcaseType(event.showcaseType))
        }
        return () => {
            if (showcaseType) {
                dispatch(setShowcaseType(null))
            }
        }
    }, [event])
    useEffect(() => {
        return () => {
            if (currentEventId) {
                dispatch(setVariable({key: `${VAR_KEYS.EventTicketDescription}-${currentEventId}`, value: null}))
            }
            dispatch(setCurrentEventId(null))
        }
    }, [organizerUrl, eventUrl])
    useEffect(() => {
        if (!organizerUrl || !eventUrl) {
            dispatch(setCurrentEventId(null))
        } else if (!event) {
            dispatch(requestCurrentEvent({organizerUrl, eventUrl}))
        }
    }, [event, user])
    useEffect(() => {
        if (currentEventId) {
            dispatch(requestVariable({key: VAR_KEYS.EventTicketDescription, foreignKey: currentEventId.toString()}))
        }
    }, [currentEventId])
    useEffect(() => {
        if (!loadingTickets) {
            setLoadingTickets(true)
        }
    }, [currentNetwork, walletAddress, currentEventId])
    useEffect(() => {
        if (loadingTickets) {
            if (currentNetwork && walletAddress && currentEventId) {
                dispatch(requestUserTicketsByEvent())
            } else {
                setLoadingTickets(false)
            }
        }
    }, [loadingTickets])
    useEffect(() => {
        if (userTickets) {
            setLoadingTickets(false)
        }
    }, [userTickets])

    const showcasesList = useMemo(() => {
        if (!event || !event.showcases) {
            return null
        }

        return event.showcases.map((item) => (<ShowcaseRow key={item.id} showcase={item}/>))
    }, [event])
    const ticketsList = useMemo(() => {
        if (!userTickets) {
            return <div>{t('status.loading')}...</div>
        }

        if (userTickets.length === 0) {
            return <div>{t('status.emptyList')}</div>
        }

        return userTickets.map((item, index) => (<EventTicketItem key={index} item={item}/>))
    }, [userTickets])

    const addShowcaseHandler = () => {
        dispatch(setModalAddShowcaseToEvent(true))
    }
    const editEventHandler = () => {
        dispatch(setModalEditEvent(true))
    }
    const editOrganizerHandler = () => {
        if (!event || !organizerUrl || !eventUrl) {
            return
        }

        dispatch(setModalEditString({
            title: t('modal.title.edit', {name: t('form.label.organizerName')}),
            label: t('form.label.organizerName'),
            text: event.organizerTitle,
            handler: (text: string) => {
                dispatch(sendRequestWithAuth(putOrganizerTitle({organizerId: event.organizerId, title: text})))
            },
        }))
    }
    const editTicketDescriptionHandler = () => {
        setEditingTicketDescription(true)
        setTicketDescription(eventTicketDescription || '')
    }
    const getTicketsHandler = () => {
        dispatch(setModalShowShowcases(true))
    }
    const publishHandler = () => {
        if (!event) {
            return
        }

        dispatch(changeEventModeration({eventId: event.id, moderation: !event.moderation}))
    }
    const saveTicketDescriptionHandler = () => {
        if (!currentEventId) {
            return
        }

        dispatch(sendVariable({
            key: VAR_KEYS.EventTicketDescription,
            foreignKey: currentEventId.toString(),
            value: ticketDescription,
        }))
        setEditingTicketDescription(false)
    }

    return <div className="page-content">
        <div className="container">
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/calendar'}>{t('section.calendar')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('section.event')}</li>
                </ol>
            </nav>
        </div>
        <div className="container">
            {event === null ?
                <div className="h4">{t('status.loading')}...</div>
                :
                event === undefined ?
                    <div className="h4">{t('error.eventNotFound')}</div>
                    :
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row mb-3 mb-sm-5 mb-lg-6">
                                <div className="col-sm">
                                    <h1 className="mb-3">{event.title}</h1>
                                    <div className="d-flex">
                                        {event.types.map(item => (
                                            <span className="badge badge-sm text-bg-primary me-1" key={item.id}>
                                                {item.title}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-auto mt-3 mt-sm-n1">
                                    <div className="d-flex flex-row flex-sm-column">
                                        {event.organizer === walletAddress ?
                                            <div>
                                                <ButtonElement link onClick={editEventHandler}>
                                                    <span><EditIcon/></span>
                                                </ButtonElement>
                                            </div>
                                            :
                                            null
                                        }
                                        <div>
                                            <button className="btn btn-link">
                                                <span><ShareIcon/></span>
                                            </button>
                                        </div>
                                        <div>
                                            <div>
                                                <AddToCalendarButton
                                                    //                                                    label={t('button.addToCalendar')}
                                                    hideTextLabelButton={true}
                                                    name={event.title}
                                                    description={`${APP_URL}/event/${event.organizerUrl}/${event.url}`}
                                                    options={['Apple', 'Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo']}
                                                    startDate={startDate}
                                                    startTime={startTime}
                                                    endDate={endDate}
                                                    endTime={endTime}
                                                    useUserTZ={true}
                                                    customCss={'/static/css/atcb.min.css'}
                                                    lightMode={'dark'}
                                                    hideCheckmark={true}
                                                    buttonStyle={'custom'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-lg-5">
                                    <div className="mb-3 mb-md-4">
                                        <div className="t-tag">{t('event.card.date')}</div>
                                        <div>{eventDate}</div>
                                    </div>
                                    <div className="mb-3 mb-md-4">
                                        <div className="t-tag">{t('event.card.by')}</div>
                                        <div>
                                            {event.organizerTitle}
                                            {event.organizer === walletAddress ?
                                                <>
                                                    {' '}
                                                    <ButtonElement
                                                        small
                                                        outline
                                                        type={'secondary'}
                                                        className={'border-0 p-1'}
                                                        onClick={editOrganizerHandler}
                                                    >
                                                        <EditIcon/>
                                                    </ButtonElement>
                                                </>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-7">
                                    <div className="mb-3 mb-md-4">
                                        <div className="t-tag">{t('event.card.location')}</div>
                                        <div>Online</div>
                                    </div>
                                    <div className="mb-3 mb-md-4">
                                        <div className="t-tag">{t('event.card.tags')}</div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 mb-6">
                                <ul className="nav nav-tabs mt-lg-6" id="eventDetails" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${selectedTab === 0 ? 'active' : ''}`}
                                            id="event-details-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#event-details-tab-pane"
                                            type="button"
                                            role="tab"
                                            aria-controls="event-details-tab-pane"
                                            aria-selected="true"
                                            onClick={() => {
                                                setSelectedTab(0)
                                            }}
                                        >{t('button.details')}
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${selectedTab === 1 ? 'active' : ''}`}
                                            id="event-tickets-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#event-tickets-tab-pane"
                                            type="button"
                                            role="tab"
                                            aria-controls="event-tickets-tab-pane"
                                            aria-selected="false"
                                            onClick={() => {
                                                setSelectedTab(1)
                                            }}
                                        >
                                            <Trans
                                                i18nKey={'button.ticketsCount'}
                                                components={[
                                                    <span className="d-none d-sm-inline"/>,
                                                    <b className="badge text-bg-success"/>
                                                ]}
                                                count={userTickets?.length || 0}
                                            />
                                            {loadingTickets ?
                                                <span className={'icon-loading-container'} style={{display: 'block'}}>
                                                    <LoadingIcon/>
                                                </span>
                                                :
                                                null
                                            }
                                        </button>
                                    </li>
                                    {event.organizer === walletAddress ?
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${selectedTab === 2 ? 'active' : ''}`}
                                                id="event-bot-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#event-bot-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="event-bot-tab-pane"
                                                aria-selected="false"
                                                onClick={() => {
                                                    setSelectedTab(2)
                                                }}
                                            >{t('button.botSettings')}
                                            </button>
                                        </li>
                                        :
                                        null
                                    }
                                </ul>
                                <div className="tab-content">
                                    <div
                                        className={`tab-pane ${selectedTab === 0 ? 'show active' : ''}`}
                                        id="event-details-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="event-details-tab"
                                        tabIndex={0}
                                    >
                                        <div className="pt-4">
                                            <div className="mb-4">
                                                {event.description?.split(/\r?\n/).map((item, index) => (
                                                    <p key={index}>{item}</p>))}
                                            </div>
                                            <div className="mb-4">
                                                <h3 className="mb-3">
                                                    {event.organizer === walletAddress ?
                                                        t('event.connectedShowcases')
                                                        :
                                                        t('event.buyTicketsHere')
                                                    }
                                                </h3>
                                                <div className="row-table">
                                                    {showcasesList}
                                                </div>
                                                {event.organizer === walletAddress && event.showcases && event.showcases.length < 3 ?
                                                    <ButtonElement
                                                        small
                                                        outline
                                                        className={'mb-4'}
                                                        onClick={addShowcaseHandler}
                                                    >
                                                        <AddIcon/>
                                                        <span>{t('button.addShowcase')}</span>
                                                    </ButtonElement>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`tab-pane ${selectedTab === 1 ? 'show active' : ''}`}
                                        id="event-details-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="event-details-tab"
                                        tabIndex={1}
                                    >
                                        <div className="pt-4">
                                            <div className="mb-4">
                                                {editingTicketDescription ?
                                                    <>
                                                        <TextareaElement
                                                            value={ticketDescription}
                                                            onChange={setTicketDescription}
                                                            additionalClass={'mb-2'}
                                                            rows={5}
                                                        />
                                                        <ButtonElement
                                                            small
                                                            outline
                                                            onClick={saveTicketDescriptionHandler}
                                                        >
                                                            <span>{t('button.save')}</span>
                                                        </ButtonElement>
                                                    </>
                                                    :
                                                    <>
                                                        <AlertElement additionalClass={'mb-2'}>
                                                            {eventTicketDescription?.split(/\r?\n/).map((item, index) => (
                                                                <p key={index}>{item}</p>
                                                            ))}
                                                        </AlertElement>
                                                        {event.organizer === walletAddress && event.showcases && event.showcases.length < 3 ?
                                                            <ButtonElement
                                                                small
                                                                outline
                                                                onClick={editTicketDescriptionHandler}
                                                            >
                                                                <EditIcon className={'me-2'}/>
                                                                <span>{t('button.editText')}</span>
                                                            </ButtonElement>
                                                            :
                                                            null
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        {walletAddress ?
                                            <div>
                                                <h3 className="mb-3">{t('event.yourTickets')}</h3>
                                                <div className="row-table">
                                                    {ticketsList}
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {event.organizer === walletAddress ?
                                        <div
                                            className={`tab-pane ${selectedTab === 2 ? 'show active' : ''}`}
                                            id="event-bot-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="event-bot-tab"
                                            tabIndex={0}
                                        >
                                            <div className="pt-4">
                                                <div className="d-flex align-items-center mb-4">
                                                    <Trans
                                                        i18nKey={'event.botSettings0'}
                                                        count={event.id}
                                                        components={[
                                                            <span className="text-muted me-2"/>,
                                                            <CopyToClipboardBlock
                                                                text={event.id}
                                                                small
                                                                additionalClass={'mt-n1'}
                                                            />
                                                        ]}
                                                    />
                                                </div>
                                                <div>
                                                    <ol className="ps-3">
                                                        <li className="mb-3">
                                                            <Trans
                                                                i18nKey={'event.botSettings1'}
                                                                components={[
                                                                    <span
                                                                        className="font-monospace badge text-bg-secondary"/>
                                                                ]}
                                                            />
                                                        </li>
                                                        <li className="mb-3">
                                                            <Trans
                                                                i18nKey={'event.botSettings2'}
                                                                components={[
                                                                    <a href={`https://t.me/${TG_AUTH_BOT}`}
                                                                       target="_blank" rel={'noreferrer'}/>
                                                                ]}
                                                            />
                                                        </li>
                                                        <li className="mb-3">
                                                            {t('event.botSettings3')}
                                                        </li>
                                                        <li className="mb-3">
                                                            <Trans
                                                                i18nKey={'event.botSettings4'}
                                                                components={[
                                                                    <span
                                                                        className="font-monospace badge text-bg-secondary"/>,
                                                                    <CopyToClipboardBlock
                                                                        text={'/init'}
                                                                        small
                                                                        additionalClass={'mt-n1'}
                                                                    />
                                                                ]}
                                                            />
                                                        </li>
                                                        <li className="mb-3">
                                                            <Trans
                                                                i18nKey={'event.botSettings5'}
                                                                count={event.id}
                                                                components={[
                                                                    <span
                                                                        className="font-monospace badge text-bg-secondary"/>,
                                                                    <CopyToClipboardBlock
                                                                        text={`/addevent ${event.id}`}
                                                                        small
                                                                        additionalClass={'mt-n1'}
                                                                    />
                                                                ]}
                                                            />
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 ps-xl-6">
                            <div id="event-sidebar">
                                <div className="event-tickets">
                                    {/*
                                    <div className="event-tickets__banner mb-2">
                                        <img src="static/img/event-tickets-banner.jpg" alt=""/>
                                    </div>
*/}
                                    <ButtonElement className="w-100" onClick={getTicketsHandler}>
                                        {t('event.card.getTickets')}
                                    </ButtonElement>
                                </div>
                                {/*
                                <div className="event-socials mt-lg-3">
                                    <div className="t-tag mb-2">Links</div>
                                    <ul className="list-socials">
                                        <li>
                                            <a className="social-link" href="#" target="_blank">
                                                <svg viewBox="0 0 24 24" width="24" height="24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M13.64 20.65a8.84 8.84 0 0 1-3.28 0 7.75 7.75 0 0 1-.78-1.41c-.33-.76-.62-1.64-.83-2.63a25.26 25.26 0 0 1 6.51-.06c-.21 1.01-.5 1.92-.83 2.69a7.75 7.75 0 0 1-.79 1.4Zm1.11 1.8A10.82 10.82 0 0 1 1.2 12a10.8 10.8 0 1 1 13.55 10.45Zm4.23-5.09c-.7.9-1.56 1.66-2.54 2.24.32-.82.6-1.73.8-2.72.63.14 1.2.3 1.74.48Zm1.05-1.75a8.77 8.77 0 0 0 0-7.22c-.75.28-1.58.51-2.47.7a25.14 25.14 0 0 1 0 5.82c.89.19 1.72.42 2.47.7Zm-4.45-1.03a27.52 27.52 0 0 0-7.16.05 23.15 23.15 0 0 1 0-5.26 27.24 27.24 0 0 0 7.16.05 23.17 23.17 0 0 1 0 5.16Zm-.32-7.13a25.54 25.54 0 0 1-6.51-.06c.21-.98.5-1.87.83-2.63.24-.56.5-1.03.78-1.4a8.85 8.85 0 0 1 3.28 0c.27.37.54.84.79 1.4.33.77.62 1.68.83 2.69Zm1.98-.33c.63-.14 1.2-.3 1.74-.48a8.84 8.84 0 0 0-2.54-2.24c.32.82.6 1.73.8 2.72ZM7.56 4.4a8.84 8.84 0 0 0-2.45 2.12c.5.19 1.07.36 1.67.5.2-.95.47-1.83.78-2.62ZM6.46 9c-.89-.2-1.7-.46-2.42-.75a8.77 8.77 0 0 0 0 7.5c.72-.3 1.53-.54 2.41-.75a25.09 25.09 0 0 1 0-6Zm.32 7.98c-.6.14-1.16.31-1.67.5.68.85 1.51 1.57 2.45 2.12-.31-.8-.57-1.67-.78-2.62Z"></path>
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="social-link" href="#" target="_blank">
                                                <svg viewBox="0 0 24 24" width="24" height="24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M12 24a12 12 0 1 0 0-24 12 12 0 0 0 0 24Zm6.35-18.4h-2.21L12.5 9.63 9.36 5.6H4.8l5.44 6.9-5.16 5.7H7.3l3.99-4.4 3.48 4.4h4.44l-5.68-7.26 4.83-5.34Zm-1.76 11.32h-1.22l-8-10.1H8.7l7.9 10.1Z"></path>
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="social-link" href="#" target="_blank">
                                                <svg viewBox="0 0 24 24" width="24" height="24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M12 24a12 12 0 1 0 0-24 12 12 0 0 0 0 24Zm-2.97-6.17c.34.16.64 0 .64-.4 0-8.98.03-9.12-.01-9.15h-.01l-3.98-2c-.45-.22-.65-.07-.65.26v8.85c0 .12.04.25.11.37.08.12.17.2.28.26l3.62 1.8Zm5.3-2-4.16-6.74v4.67l4.16 2.06Zm4.65 1.6v-8.2l-4.2 6.82 3.43 1.7c.12.07.24.1.36.1.13 0 .23-.03.3-.11.07-.08.11-.19.11-.32Zm-2.02-5.87c1.33-2.16 2-3.24 2-3.26 0-.02-.01-.04-.03-.04l-4.22-2.1a.47.47 0 0 0-.6.17l-2.53 4.1 3.04 4.92 2.34-3.79Z"></path>
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="social-link" href="#" target="_blank">
                                                <svg viewBox="0 0 24 24" width="24" height="24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M0 12a12 12 0 1 1 24 0 12 12 0 0 1-24 0Zm19.19 3.62c.3-1.17.3-3.6.3-3.6s.02-2.45-.3-3.63a1.88 1.88 0 0 0-1.32-1.32C16.69 6.75 12 6.75 12 6.75s-4.7 0-5.87.3c-.63.18-1.15.7-1.32 1.34-.3 1.18-.3 3.61-.3 3.61s0 2.45.3 3.6c.17.65.68 1.16 1.32 1.33 1.19.32 5.87.32 5.87.32s4.7 0 5.87-.3a1.88 1.88 0 0 0 1.32-1.33ZM14.4 12l-3.9 2.25v-4.5L14.4 12Z"></path>
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="event-socials mt-lg-3">
                                    <div className="t-tag mb-2">Links</div>
                                    <div className="mb-2"><a href="#" target="_blank">// official website</a></div>
                                    <div className="mb-2"><a href="#" target="_blank">// telegram</a></div>
                                    <div className="mb-2"><a href="#" target="_blank">// medium </a></div>
                                </div>
*/}
                            </div>
                        </div>
                    </div>
            }
        </div>
    </div>
}

export default Event
