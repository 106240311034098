import React from 'react'
import {IconProps} from '../../store/types'

const Share = (props: IconProps) => {
    return <svg className={props.className} viewBox="0 0 24 24" width="24" height="24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16 5a2 2 0 1 0 4.01 0A2 2 0 0 0 16 5Zm2 4.01a4 4 0 0 1-2.76-1.11L9.88 11a4.01 4.01 0 0 1-.13 2.43l5.19 3a4 4 0 1 1-.87 1.8l-5.49-3.16a4 4 0 1 1 .32-5.82l5.28-3.05A4 4 0 1 1 18 9Zm-2 10a2 2 0 1 0 4.01 0A2 2 0 0 0 16 19ZM6 14A2 2 0 1 1 6 10a2 2 0 0 1 0 4.01Z"/>
    </svg>
}

export default Share
