import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {
    getCurrentNetwork,
    getSelectedMintCollection, getShowcaseType,
    getWalletAddress, sendRequestWithAuth,
    setModalCreateMintCollection, setSelectedMintCollection
} from '../../store/appSlice'
import {getCollections, requestMintCollections, setCollections} from '../../store/mintSlice'
import {DropdownPlaceholder, DropdownSelector} from '../elements'
import CopyToClipboardInput from '../elements/CopyToClipboardInput'
import {IDropdownItem} from '../../store/types'

interface PropsType {
    errorText?: string
    fullWidth?: boolean
}

const SelectMintCollection = (props: PropsType) => {
    const {t} = useTranslation()
    const [firstRender, setFirstRender] = useState(true)
    const currentNetwork = useSelector(getCurrentNetwork)
    const collections = useSelector(getCollections)
    const selectedCollection = useSelector(getSelectedMintCollection)
    const showcaseType = useSelector(getShowcaseType)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        return () => {
            dispatch(setCollections(null))
        }
    }, [])
    useEffect(() => {
        setFirstRender(false)
        if (firstRender && collections) {
            return
        }

        if (currentNetwork && walletAddress) {
            dispatch(setCollections(null))
        }
    }, [currentNetwork, walletAddress])
    useEffect(() => {
        if (collections && collections.length > 0) {
            let found = false
            for (let item of collections) {
                if (selectedCollection === item.contractAddress) {
                    found = true
                    break
                }
            }
            if (!found) {
                selectCollectionHandler(collections[collections.length - 1].contractAddress)
            }
        } else if (currentNetwork && walletAddress && !collections) {
            dispatch(sendRequestWithAuth(requestMintCollections()))
        }
    }, [collections])

    if (!showcaseType) {
        return null
    }

    const collectionsList: IDropdownItem[] = collections?.map((item): IDropdownItem => {
        return {id: item.contractAddress, name: item.name}
    }) || []

    const addCollectionHandler = () => {
        dispatch(setModalCreateMintCollection(true))
    }
    const selectCollectionHandler = (address: string) => {
        dispatch(setSelectedMintCollection(address))
    }

    return <>
        <div className="row">
            <div className={!props.fullWidth ? 'col-9 col-md-8 col-lg-6 col-xl-5' : undefined}>
                <div className="mb-3">
                    {walletAddress && collections ?
                        <DropdownSelector
                            errorText={props.errorText}
                            list={collectionsList}
                            setItem={selectCollectionHandler}
                            currentItem={selectedCollection}
                            firstItem={{id: 'firstItem', name: t('button.addCollection')}}
                            firstItemAction={addCollectionHandler}
                            label={t('form.label.ticketCollection')}
                        />
                        :
                        <DropdownPlaceholder
                            title={walletAddress ? `${t('status.loading')}...` : t('status.waitingCollection')}
                            label={t('form.label.ticketCollection')}
                        />
                    }
                </div>
            </div>
        </div>
        {collections && selectedCollection ?
            <div className="row">
                <div className={!props.fullWidth ? 'col-lg-6 col-xl-5' : undefined}>
                    <CopyToClipboardInput text={selectedCollection} outline/>
                </div>
            </div>
            :
            null
        }
    </>
}

export default SelectMintCollection
