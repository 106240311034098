import {createContext} from 'react'
import {TDesignMode} from '../store/types'

interface IThemeContext {
    dark: boolean
    toggleDark: () => void
    designMode: TDesignMode | null,
    changeDesignMode: (val: TDesignMode | null) => void
}

const initialState: IThemeContext = {
    dark: true,
    toggleDark: () => {},
    designMode: null,
    changeDesignMode: () => {},
}

const ThemeContext = createContext<IThemeContext>(initialState)

export default ThemeContext
