import React from 'react'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {setModalShowShowcases, setRedirectPath} from '../../store/appSlice'
import {CHAINS} from '../../utils/constants'
import {DBShowcase} from '../../store/types'
import {ButtonElement} from '../elements'

interface ParamsType {
    showcase: DBShowcase
}

const ShowcaseRow = (params: ParamsType) => {
    const {t} = useTranslation()
    const {showcase} = params
    let showcaseUrl = ''
    switch (showcase.contract) {
        case CHAINS[`0x${showcase.chain.toString(16)}`].launchpadContract:
            showcaseUrl = 'showcase'
            break
        case CHAINS[`0x${showcase.chain.toString(16)}`].lazyMintingContract:
            showcaseUrl = 'lazyshowcase'
            break
        case CHAINS[`0x${showcase.chain.toString(16)}`].showcaseV2Contract:
            showcaseUrl = 'showcases'
            break
    }

    const dispatch = useDispatch<AppDispatch>()

    const buyHandler = () => {
        dispatch(setRedirectPath(`/${showcaseUrl}/${showcase.chain}/${showcase.name}`))
        dispatch(setModalShowShowcases(false))
    }

    return <div>
        <div className="row pt-2 pt-md-1 align-items-center">
            {showcaseUrl !== '' ?
                <>
                    <div className="col-5 col-lg-7 pb-2 pb-md-1">{showcase.title}</div>
                    <div className="col pb-2 pb-md-1">
                        <span className="text-muted">Left: </span><span>0</span>
                    </div>
                    <div className="col-auto pb-2 pb-md-1">
                        <ButtonElement link className={'w-100'} onClick={buyHandler}>
                            {t('button.buy')}
                        </ButtonElement>
                    </div>
                </>
                :
                t('error.showcaseNotFound')
            }
        </div>
    </div>
}

export default ShowcaseRow
