import React, {useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {Trans, useTranslation} from 'react-i18next'
import {SelectSbtCollection} from './index'
import {AppDispatch} from '../../store/store'
import {
    getCurrentNetwork,
    getSelectedSbtCollection, getShowcaseType,
    getTokenSmartcontracts,
    getWalletAddress, sendRequestWithAuth
} from '../../store/appSlice'
import {getTickets, requestTokens, resetTickets, setTickets} from '../../store/ticketsSlice'
import {getCollections} from '../../store/sbtSlice'
import {ButtonElement, CheckboxElement, DropdownSelector, InputElement} from '../elements'
import {getEventsObject} from '../../store/eventsSlice'
import {FiltersIcon} from '../icons'
import {SbtTicketRow} from '../tickets'
import DateFilter from '../elements/DateFilter'
import {getTokens} from '../../store/tokensSlice'
import {IDropdownItem} from '../../store/types'
import {getCollections as getMintCollections, requestMintCollections} from '../../store/mintSlice'
import {getDateFilter, getDateFilterAsc, setDateFilter} from '../../store/inputSlice'
import {CHAINS} from '../../utils/constants'

const SbtCollectionPage = () => {
    const {t, i18n} = useTranslation()
    const [addressFilter, setAddressFilter] = useState('')
    const [checkedSmartcontracts, setCheckedSmartcontracts] = useState(true)
    const [eventsFilter, setEventsFilter] = useState<IDropdownItem[]>([])
    const [levelsFilter, setLevelsFilter] = useState<IDropdownItem[]>([])
    const [selectedEventFilter, setSelectedEventFilter] = useState(0)
    const [selectedLevelFilter, setSelectedLevelFilter] = useState('')
    const [showFilters, setShowFilters] = useState(false)
    const collections = useSelector(getCollections)
    const currentNetwork = useSelector(getCurrentNetwork)
    const dateFilter = useSelector(getDateFilter)
    const dateFilterAsc = useSelector(getDateFilterAsc)
    const events = useSelector(getEventsObject)
    const mintCollections = useSelector(getMintCollections)
    const selectedCollection = useSelector(getSelectedSbtCollection)
    const showcaseType = useSelector(getShowcaseType)
    const tickets = useSelector(getTickets)
    const tokens = useSelector(getTokens)
    const tokenSmartcontracts = useSelector(getTokenSmartcontracts, shallowEqual)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        const timer = setInterval(() => {
            dispatch(requestTokens())
        }, 300000)

        return () => {
            clearInterval(timer)
            dispatch(resetTickets())
            dispatch(setDateFilter(null))
        }
    }, [])
    useEffect(() => {
        if (tickets) {
            dispatch(setTickets(null))
        } else if (walletAddress) {
            dispatch(requestTokens())
        }
        if (currentNetwork && walletAddress) {
            dispatch(sendRequestWithAuth(requestMintCollections()))
        }
    }, [currentNetwork, walletAddress])
    useEffect(() => {
        if (!tickets && walletAddress) {
            dispatch(requestTokens())
        }
        let eventsList: IDropdownItem[] = [{id: 0, name: t('form.label.allEvents')}]
        let levelsList: IDropdownItem[] = [{id: '', name: t('form.label.allLevels')}]
        let ids: number[] = []
        let ids2: string[] = []
        for (let item of tickets || []) {
            if (item.eventId && events && events[item.eventId] && ids.indexOf(item.eventId) < 0) {
                eventsList.push({id: item.eventId, name: events[item.eventId].title})
                ids.push(item.eventId)
            }
            if (item.level && ids2.indexOf(item.level.title) < 0) {
                levelsList.push({id: item.level.title, name: item.level.title})
                ids2.push(item.level.title)
            }
        }
        setEventsFilter(eventsList)
        setLevelsFilter(levelsList)
    }, [tickets, events, i18n.language])

    const ticketsList = useMemo(() => {
        if (!tickets || !currentNetwork) {
            return <div>{t('status.loadingTicketsDescription')}</div>
        }

        if (tickets.length === 0) {
            return <div>
                <Trans i18nKey={'certificate.createTicketsDescription'} components={[<Link to={'/mintfactory'}/>]}/>
            </div>
        }

        let filtered = [...tickets].sort((itemA, itemB) => {
            return (itemA.blockNum - itemB.blockNum) * (dateFilterAsc ? 1 : -1)
        })
        filtered = filtered.filter((item) => {
            return item.tokenId.toString().indexOf(addressFilter) >= 0 || item.contract.indexOf(addressFilter.toLowerCase()) >= 0
        }).filter((item) => {
            return selectedEventFilter === 0 || item.eventId === selectedEventFilter
        }).filter((item) => {
            return selectedLevelFilter === '' || (item.level && item.level.title === selectedLevelFilter)
        }).filter((item) => {
            return !checkedSmartcontracts || tokenSmartcontracts.indexOf(item.contract) >= 0
        }).filter((item) => {
            if (!dateFilter) {
                return true
            }

            const date = tokens?.[`${item.contract}-${item.tokenId}`]?.date
            if (!date) {
                return false
            }

            const delta = date.getTime() - dateFilter.getTime()
            return delta >= 0 && delta < 86400000
        })
        if (filtered.length === 0) {
            return <div>{t('status.noNftFitsFilter')}</div>
        } else {
            return filtered.map((item) => (<SbtTicketRow key={`${item.contract}-${item.tokenId}`} item={item}/>))
        }
    }, [tickets, addressFilter, checkedSmartcontracts, dateFilter, dateFilterAsc, selectedEventFilter, selectedLevelFilter])

    const addressFilterHandler = (value: string) => {
        if (value === '' || /^[a-zA-Z0-9]+$/.test(value)) {
            setAddressFilter(value)
        }
    }

    return <div className="page-content">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="text-primary mb-1">{t(`menu.${showcaseType}`)}</div>
                    <h1>{t('header.certificates')}</h1>
                </div>
            </div>
            <div className="mb-5">
                <p>{t('certificate.pageDescription')}</p>
                {currentNetwork ?
                    <p>
                        <Trans
                            i18nKey={`alert.gasZipDescription`}
                            values={{name: CHAINS[currentNetwork].label}}
                            components={[<a href={'https://gas.zip'} target='_blank' rel='noopener noreferrer'/>]}
                        />
                    </p>
                    :
                    null
                }
            </div>
            <SelectSbtCollection/>
            {collections && selectedCollection ?
                <>
                    <div className="row gx-3 gx-xl-4 mb-2 mb-xl-2">
                        <div
                            className="col-12 col-md-4 col-lg-auto mb-3 order-1 order-md-0 col-filter__mob-toggled"
                            style={showFilters ? {display: 'block'} : {}}
                        >
                        <DateFilter/>
                        </div>
                        <div
                            className="col-12 col-md-4 col-lg-2 mb-3 order-1 order-md-0 col-filter__mob-toggled"
                            style={showFilters ? {display: 'block'} : {}}
                        >
                            <DropdownSelector
                                list={eventsFilter}
                                setItem={setSelectedEventFilter}
                                currentItem={selectedEventFilter}
                                button
                            />
                        </div>
                        <div
                            className="col-12 col-md-4 col-lg-2 mb-3 order-1 order-md-0 col-filter__mob-toggled"
                            style={showFilters ? {display: 'block'} : {}}
                        >
                            <DropdownSelector
                                list={levelsFilter}
                                setItem={setSelectedLevelFilter}
                                currentItem={selectedLevelFilter}
                                button
                            />
                        </div>
                        <div className="col-8 col-md-4 col-lg-3 col-xl-2 mb-4 order-0">
                            <InputElement
                                value={addressFilter}
                                onChange={addressFilterHandler}
                                additionalClass={'form-control form-control-sm control-search'}
                                placeholder={t('form.label.addressOrId')}
                            />
                        </div>
                        <div className="col-4 mb-4 order-0 d-md-none">
                            <ButtonElement
                                small
                                outline
                                className={`btn-filter-toggle w-100 ${showFilters ? 'show' : ''}`}
                                onClick={() => {
                                    setShowFilters(!showFilters)
                                }}
                            >
                                <FiltersIcon className="me-2"/>
                                <span>{t('button.filters')}</span>
                            </ButtonElement>
                        </div>
                        <div
                            className="col-md-8 col-lg-auto order-1 mb-4 col-filter__mob-toggled"
                            style={showFilters ? {display: 'block'} : {}}
                        >
                            <CheckboxElement
                                checked={checkedSmartcontracts}
                                onChange={() => {
                                    setCheckedSmartcontracts(!checkedSmartcontracts)
                                }}
                                label={t('form.label.myshchSmartContracts')}
                                loading={mintCollections === null}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="row-table">
                            {ticketsList}
                        </div>
                    </div>
                </>
                :
                null
            }
        </div>
    </div>
}

export default SbtCollectionPage
