import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {createBrowserRouter, Outlet, RouterProvider} from 'react-router-dom'
import {CheckNetwork, Initialize, LayoutClassic, LayoutDefault, LayoutSmart, LayoutOnChain} from './components/layouts'
import {About, ComingSoon, NotFound, NotFoundQuote} from './components/static'
import {AddMintCollection, MintCollectionPage, MintPage} from './components/mint'
import {CheckTickets, Tickets} from './components/tickets'
import {Launchpad, Showcase} from './components/launchpad'
import {AddOrEditCalendarEvent, Calendar, CalendarByMonth, Event as CalendarEvent} from './components/calendar'
import {Event, Events, OnChainEvent, OnChainEventsManagement} from './components/events'
import {Profile} from './components/profile'
import {ShowSbtCollection, SbtCollectionPage} from './components/sbt'
import {getCurrentNetwork, getInitialized, initialize, initializeNetwork, networkChanged} from './store/appSlice'
import {AppDispatch} from './store/store'
import {LazyMinting, LazyShowcase} from './components/lazyMinting'
import {Whitelists} from './components/whitelists'
import {EditShowcasesV2, ShowcaseV2} from './components/showcase'
import {MAIN_MENUS, SUB_MENU} from './utils/constants'
import {EventStatistic, Statistic} from './components/statistic'
import {WizardLazy} from './components/wizards'
import {Gifts} from './components/gifts'
import {Authorization} from './components/auth'

const App = () => {
    const [lastNetwork, setLastNetwork] = useState<string | null>(null)
    const currentNetwork = useSelector(getCurrentNetwork)
    const initialized = useSelector(getInitialized)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(initialize())
    }, [])
    useEffect(() => {
        if (lastNetwork) {
            if (lastNetwork !== currentNetwork) {
                dispatch(networkChanged())
            }
        } else if (currentNetwork) {
            dispatch(initializeNetwork())
        }
        setLastNetwork(currentNetwork)
    }, [currentNetwork])

    if (!initialized) {
        return <Initialize/>
    }

    const router = createBrowserRouter([
        {
            path: '/',
            element: <LayoutDefault/>,
            children: [
                {path: '/', element: <About/>},
                {path: '/addmintcollection', element: <AddMintCollection/>},
                {path: '/airdrop-nft', element: <ComingSoon/>},
                {path: '/accesses/tgbot', element: <ComingSoon/>},
                {
                    path: '/calendar',
                    element: <Outlet/>,
                    children: [
                        {path: '/calendar', element: <Calendar/>},
                        {path: '/calendar/addevent', element: <AddOrEditCalendarEvent/>},
                        {path: '/calendar/bymonth', element: <CalendarByMonth/>},
                        {path: '/calendar/events/:eventId', element: <CalendarEvent/>},
                        {path: '/calendar/events/:eventId/edit', element: <AddOrEditCalendarEvent/>},
                    ],
                    handle: {mainMenu: MAIN_MENUS.calendar},
                },
                {
                    path: '/classic',
                    element: <LayoutClassic/>,
                    children: [
                        {
                            path: '/classic/events',
                            element: <Events/>,
                            handle: {mainMenu: MAIN_MENUS.classic, subMenu: SUB_MENU.classicEvents},
                        },
                        {
                            path: '/classic/mint',
                            element: <MintCollectionPage/>,
                            handle: {mainMenu: MAIN_MENUS.classic, subMenu: SUB_MENU.classicMint},
                        },
                        {
                            path: '/classic/sbt',
                            element: <CheckNetwork children={<SbtCollectionPage/>}/>,
                            handle: {mainMenu: MAIN_MENUS.classic, subMenu: SUB_MENU.classicSbt},
                        },
                        {
                            path: '/classic/showcases',
                            element: <EditShowcasesV2/>,
                            handle: {mainMenu: MAIN_MENUS.classic, subMenu: SUB_MENU.classicShowcases},
                        },
                        {
                            path: '/classic/stats/events',
                            element: <CheckNetwork children={<Statistic/>}/>,
                            handle: {mainMenu: MAIN_MENUS.classic, subMenu: SUB_MENU.classicStat},
                        },
                        {
                            path: '/classic/stats/events/:eventId',
                            element: <CheckNetwork children={<EventStatistic/>}/>,
                            handle: {mainMenu: MAIN_MENUS.classic, subMenu: SUB_MENU.classicStat},
                        },
                    ],
                },
                {path: '/event/:organizerUrl/:eventUrl', element: <Event/>},
                {
                    path: '/gifts',
                    element: <Outlet/>,
                    children: [
                        {
                            path: '/gifts',
                            element: <Gifts/>,
                        },
                    ],
                    handle: {mainMenu: MAIN_MENUS.gifts},
                },
                {
                    path: '/mintcollection',
                    element: <CheckNetwork children={<MintPage/>}/>,
                },
                {
                    path: '/onchain',
                    element: <LayoutOnChain/>,
                    children: [
                        {
                            path: '/onchain/events',
                            element: <CheckNetwork children={<OnChainEventsManagement/>}/>,
                            handle: {mainMenu: MAIN_MENUS.onchain, subMenu: SUB_MENU.onchainEvents},
                        },
                        {
                            path: '/onchain/events/:network/:contractAddress',
                            element: <OnChainEvent/>,
                            handle: {mainMenu: MAIN_MENUS.onchain, subMenu: SUB_MENU.onchainEvents},
                        },
                        {
                            path: '/onchain/mint',
                            element: <MintCollectionPage/>,
                            handle: {mainMenu: MAIN_MENUS.onchain, subMenu: SUB_MENU.onchainMint},
                        },
                        {
                            path: '/onchain/showcases',
                            element: <EditShowcasesV2/>,
                            handle: {mainMenu: MAIN_MENUS.onchain, subMenu: SUB_MENU.onchainShowcases},
                        },
                        {
                            path: '/onchain/stats',
                            element: <ComingSoon/>,
                            handle: {mainMenu: MAIN_MENUS.onchain, subMenu: SUB_MENU.onchainStat},
                        },
                    ],
                },
                {path: '/profile', element: <Profile/>},
                {
                    path: '/sbt/:network/:contractAddress',
                    element: <ShowSbtCollection/>,
                },
                {
                    path: '/showcases/:network/:showcaseName',
                    element: <ShowcaseV2/>,
                },
                {
                    path: '/smart',
                    element: <LayoutSmart/>,
                    children: [
                        {
                            path: '/smart/events',
                            element: <Events/>,
                            handle: {mainMenu: MAIN_MENUS.smart, subMenu: SUB_MENU.smartEvents},
                        },
                        {
                            path: '/smart/mint',
                            element: <MintCollectionPage/>,
                            handle: {mainMenu: MAIN_MENUS.smart, subMenu: SUB_MENU.smartMint},
                        },
                        {
                            path: '/smart/sbt',
                            element: <CheckNetwork children={<SbtCollectionPage/>}/>,
                            handle: {mainMenu: MAIN_MENUS.smart, subMenu: SUB_MENU.smartSbt},
                        },
                        {
                            path: '/smart/showcases',
                            element: <EditShowcasesV2/>,
                            handle: {mainMenu: MAIN_MENUS.smart, subMenu: SUB_MENU.smartShowcases},
                        },
                        {
                            path: '/smart/stats/events',
                            element: <CheckNetwork children={<Statistic/>}/>,
                            handle: {mainMenu: MAIN_MENUS.smart, subMenu: SUB_MENU.smartStat},
                        },
                        {
                            path: '/smart/stats/events/:eventId',
                            element: <CheckNetwork children={<EventStatistic/>}/>,
                            handle: {mainMenu: MAIN_MENUS.smart, subMenu: SUB_MENU.smartStat},
                        },
                        {
                            path: '/smart/wizard',
                            element: <WizardLazy/>,
                            handle: {mainMenu: MAIN_MENUS.smart},
                        },
                    ],
                },
                {path: '/tickets', element: <CheckNetwork children={<Tickets/>}/>},
                {path: '/tickets/check', element: <CheckTickets/>},
                {path: '/whitelists', element: <Whitelists/>},
                //pages for old contracts
                {path: '/lazyminting', element: <CheckNetwork networkList={['0xaa36a7']} children={<LazyMinting/>}/>},
                {path: '/lazyshowcase/:network/:showcaseName', element: <LazyShowcase/>},
                {
                    path: '/showcase',
                    element: <CheckNetwork networkList={['0x38', '0x89', '0xaa36a7']} children={<Launchpad/>}/>
                },
                {path: '/showcase/:network/:showcaseName', element: <Showcase/>},
                //END pages for old contracts
                {path: '*', element: <NotFoundQuote/>},
            ],
        },
    ])

    return <>
        <Authorization/>
        <RouterProvider router={router}/>
    </>

}

export default App
