import React, {useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {checkWallet, setModalCreateMintCollection} from '../../store/appSlice'
import {ButtonElement, InputElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {checkNameString} from '../../utils/functions'
import {createMintCollection} from '../../store/mintSlice'
import {CloseIcon} from '../icons'

interface propsType {
    show: boolean
}

const ModalCreateMintCollection = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const [factoryName, setFactoryName] = useState('')
    const [factoryNameError, setFactoryNameError] = useState('')
    const [factorySymbol, setFactorySymbol] = useState('')
    const [factorySymbolError, setFactorySymbolError] = useState('')

    const dispatch = useDispatch<AppDispatch>()

    const closeModal = () => {
        dispatch(setModalCreateMintCollection(false))
    }
    const createFactory = () => {
        let error = false
        const name = factoryName.trim()
        const symbol = factorySymbol.trim()

        if (name === '') {
            error = true
            setFactoryNameError(t('error.mustEnter', {name: t('form.label.collectionName')}))
        } else if (!checkNameString(name)) {
            error = true
            setFactoryNameError(`${t('error.wrong', {name: t('form.label.collectionName')})} (a-Z, 0-9, "-", ".", "_", "~", " ")`)
        } else {
            setFactoryNameError('')
        }
        if (symbol === '') {
            error = true
            setFactorySymbolError(t('error.mustEnter', {name: t('form.label.collectionTicker')}))
        } else if (!/^[a-zA-Z0-9]*$/.test(symbol)) {
            error = true
            setFactorySymbolError(`${t('error.wrong', {name: t('form.label.collectionTicker')})} (a-Z, 0-9)`)
        } else {
            setFactorySymbolError('')
        }

        if (error) {
            return
        }
        dispatch(checkWallet({request: createMintCollection({name: factoryName, symbol: factorySymbol})}))
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Select event"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header pb-3">
                    <h2 className="modal-title">{t('modal.title.createCollection')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="mb-4">
                        {t('modal.createCollectionDescription')}
                    </div>
                    <div className={'mb-3'}>
                        <InputElement
                            errorText={factoryNameError}
                            label={<>{t('form.label.collectionName')} <small>(a-Z, 0-9, "-", ".", "_", "~", "
                                ")</small></>}
                            value={factoryName}
                            onChange={(value) => {
                                if (checkNameString(value)) {
                                    setFactoryName(value)
                                    setFactoryNameError('')
                                } else {
                                    setFactoryNameError(`${t('error.wrong', {name: t('form.label.collectionName')})} (a-Z, 0-9, "-", ".", "_", "~", " ")`)
                                }
                            }}
                        />
                    </div>
                    <div>
                        <InputElement
                            errorText={factorySymbolError}
                            label={<>{t('form.label.collectionTicker')} <small>(a-Z, 0-9)</small></>}
                            value={factorySymbol}
                            onChange={(value) => {
                                if (/^[a-zA-Z0-9]*$/.test(value)) {
                                    setFactorySymbol(value)
                                    setFactorySymbolError('')
                                } else {
                                    setFactorySymbolError(`${t('error.wrong', {name: t('form.label.collectionTicker')})} (a-Z, 0-9)`)
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <ButtonElement
                        className={'flex-grow-1 flex-lg-grow-0'}
                        onClick={createFactory}
                    >{t('button.create')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalCreateMintCollection
