import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {getWalletAddress, sendRequestWithAuth, setModalEditString} from '../../store/appSlice'
import {
    getOrganizers, getSelectedOrganizerId, getSelectedOrganizerName,
    requestOrganizers, putOrganizerTitle, setOrganizers,
    setSelectedOrganizerId
} from '../../store/organizersSlice'
import {ConnectWalletButton} from '../wallet'
import {ButtonElement, DropdownPlaceholder, DropdownSelector} from '../elements'
import {EditIcon} from '../icons'
import {getJwt} from '../../store/authSlice'
import {IDropdownItem} from '../../store/types'

interface propsType {
    error?: string
}

const SelectOrganizer = (props: propsType) => {
    const {t} = useTranslation()
    const jwt = useSelector(getJwt)
    const organizers = useSelector(getOrganizers)
    const selectedOrganizerId = useSelector(getSelectedOrganizerId)
    const organizerName = useSelector(getSelectedOrganizerName)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        return () => {
            dispatch(setOrganizers(null))
        }
    }, [walletAddress])
    useEffect(() => {
        if (organizers) {
            let found = false
            for (let org of organizers) {
                if (org.id === selectedOrganizerId) {
                    found = true
                    break
                }
            }
            if (!found && organizers.length > 0) {
                dispatch(setSelectedOrganizerId(organizers[0].id))
            }
        } else if (walletAddress) {
            dispatch(sendRequestWithAuth(requestOrganizers()))
        }
    }, [organizers, jwt])

    const organizersList: IDropdownItem[] = organizers?.map((item): IDropdownItem => {
        return {id: item.id, name: item.name}
    }) || []
    const editHandler = () => {
        if (selectedOrganizerId === null) {
            return
        }

        dispatch(setModalEditString({
            title: t('modal.title.edit', {name: t('form.label.organizerName')}),
            label: t('form.label.organizerName'),
            text: organizerName,
            handler: (text: string) => {
                dispatch(sendRequestWithAuth(putOrganizerTitle({organizerId: selectedOrganizerId, title: text})))
            },
        }))
    }

    return <>
        <div className="d-flex align-items-center justify-content-between">
            <label className="form-label">{t('form.label.organizerName')} <span className="text-danger">*</span></label>
            {organizerName !== '' ?
                <ButtonElement
                    small
                    outline
                    type={'secondary'}
                    className={'border-0 p-1 mb-1 mt-n2'}
                    onClick={editHandler}
                >
                    <EditIcon/>
                </ButtonElement>
                :
                null
            }
        </div>
        {organizers ?
            <DropdownSelector
                list={organizersList}
                setItem={(id) => {
                    dispatch(setSelectedOrganizerId(id))
                }}
                currentItem={selectedOrganizerId}
                errorText={props.error}
            />
            :
            walletAddress ?
                <DropdownPlaceholder title={`${t('status.loading')}...`}/>
                :
                <div>
                    <ConnectWalletButton/>
                </div>
        }
    </>
}

export default SelectOrganizer
