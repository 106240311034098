import React, {ReactNode, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {NavLink, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {
    getWalletAddress,
    setModalAddEventToShowcase,
    setModalConfirmation,
    setModalEditDisplay
} from '../../store/appSlice'
import {
    getShowcase,
    getShowcaseEvents,
    requestShowcase,
    requestShowcaseEvents,
    setShowcase, setShowcaseEvents
} from '../../store/launchpadSlice'
import {CHAINS, SHOWCASE_TOKENS_ON_PAGE} from '../../utils/constants'
import {getCoins, requestCoins, requestCustomCoins} from '../../store/coinsSlice'
import {TokenCard} from '../tokens'
import {NotFound} from '../static'
import {getDisplayHash} from '../../utils/functions'
import {AddIcon, CrossIcon, EditIcon, LoadingIcon} from '../icons'
import {AlertElement, ButtonElement} from '../elements'
import {delShowcaseFromEvent} from '../../store/eventsSlice'

const Showcase = () => {
    const {t} = useTranslation()
    let {network, showcaseName} = useParams()
    network = `0x${Number(network).toString(16)}`
    if (!CHAINS[network]) {
        network = undefined
    }
    const [loadedShowcase, setLoadedShowcase] = useState(false)
    const [nextPage, setNextPage] = useState(1)
    const coins = useSelector(getCoins)
    const showcase = useSelector(getShowcase)
    const showcaseEvents = useSelector(getShowcaseEvents)
    const currentDate = Math.floor(Date.now() / 1000)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        console.log(`showcase name hash: ${getDisplayHash(showcaseName || '')}`)
        dispatch(requestCoins(network))
        if (showcaseName) {
            dispatch(requestShowcase({displayName: showcaseName, network}))
        }
        return () => {
            dispatch(setShowcase(null))
        }
    }, [network, showcaseName])
    useEffect(() => {
        if (showcaseName) {
            if (loadedShowcase && !showcase) {
                setLoadedShowcase(false)
            } else if (!loadedShowcase && showcase && network) {
                setLoadedShowcase(true)
                dispatch(requestCustomCoins(network))
            }
        }
    }, [loadedShowcase, network, showcase])
    useEffect(() => {
        if (showcaseName && loadedShowcase && showcase && network && !showcaseEvents) {
            dispatch(requestShowcaseEvents({
                contract: CHAINS[network].launchpadContract,
                displayName: showcaseName,
                network
            }))
        }
        if (showcaseEvents) {
            return () => {
                dispatch(setShowcaseEvents(null))
            }
        }
    }, [loadedShowcase, network, showcase, showcaseEvents])

    const tokenList = useMemo(() => {
        if (!showcase) {
            return null
        }

        if (showcase.items.length === 0) {
            return <AlertElement centered type={'warning'}>{t('status.showcaseEmpty')}</AlertElement>
        }

        let tokens: ReactNode[] = []
        for (let i = 0; i < SHOWCASE_TOKENS_ON_PAGE * nextPage; i++) {
            if (showcase.items.length <= i) {
                break
            }

            let token = showcase.items[i]
            tokens.push(<TokenCard
                key={`${token.nft.asset.contractAddress}-${token.nft.tokenId}`}
                tokenCard={token}
                owner={showcase.owner}
                network={network}
                eventLink={showcaseEvents && showcaseEvents[0] ? `/event/${showcaseEvents[0].organizerUrl}/${showcaseEvents[0].url}` : ''}
            />)
        }
        if (showcase.items.length > SHOWCASE_TOKENS_ON_PAGE * nextPage) {
            tokens.push(<ButtonElement
                key={'loadMore'}
                outline
                onClick={() => {
                    setNextPage(nextPage + 1)
                }}
            >{t('button.loadMore')}</ButtonElement>)
        }
        return tokens
    }, [showcase, showcaseEvents, coins, nextPage])

    if (!showcaseName) {
        return <NotFound/>
    }

    const addEventHandler = () => {
        if (network && showcaseName) {
            dispatch(setModalAddEventToShowcase({
                contract: CHAINS[network].launchpadContract,
                network,
                showcaseName,
            }))
        }
    }
    const delEventHandler = (eventId: number) => {
        let name = ''
        for (let item of showcaseEvents || []) {
            if (item.id === eventId) {
                name = item.title
                break
            }
        }
        let showcaseId = 0
        for (let item of showcaseEvents || []) {
            if (item.id === eventId) {
                for (let show of item.showcases || []) {
                    if (network && show.chain === Number(network) &&
                        show.contract === CHAINS[network].launchpadContract && show.name === showcaseName
                    ) {
                        showcaseId = show.id
                        break
                    }
                }
            }
        }
        if (name === '' || showcaseId === 0) {
            return
        }

        dispatch(setModalConfirmation({
            title: t('modal.deleteEventFromShowcase', {name}),
            confirmAction: () => {
                dispatch(delShowcaseFromEvent({eventId, showcaseId}))
            },
        }))
    }
    const editShowcaseHandler = () => {
        dispatch(setModalEditDisplay(showcaseName || ''))
    }

    return <div className="page-content">
        <div className="container">
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={'/showcase'}>{t('section.showcaseManagement')}</NavLink></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('section.showcaseView')}</li>
                </ol>
            </nav>
        </div>
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1 className="mb-3">{showcaseName}</h1>
                    <div className="mb-4 pb-4">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <span className="text-muted me-2">{t('word.items')}:</span>
                                <span>{showcase?.items.length}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    {showcase?.owner === walletAddress ?
                        <>
                            <ButtonElement link className={'p-2 mt-n1 d-md-none'} onClick={editShowcaseHandler}>
                                <span><EditIcon/></span>
                            </ButtonElement>
                            <ButtonElement link className={'mt-n1 d-none d-md-flex'}
                                           onClick={editShowcaseHandler}>
                                <span className="me-2"><EditIcon/></span>
                                <span>{t('button.editShowcase')}</span>
                            </ButtonElement>
                        </>
                        :
                        null
                    }
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="mb-4 pb-4">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                {showcaseEvents && showcaseEvents.length ?
                                    <>
                                        <p>
                                            {t('showcase.goToEvent1')}
                                            {' '}
                                            {showcaseEvents.map((item, index) => (<React.Fragment key={item.id}>
                                                    <NavLink to={`/event/${item.organizerUrl}/${item.url}`}>{item.title}</NavLink>
                                                    {showcase?.owner === walletAddress ?
                                                        <ButtonElement
                                                            link
                                                            small
                                                            className={'px-2'}
                                                            onClick={() => delEventHandler(item.id)}
                                                        >
                                                            <span className={'me-2'}><CrossIcon/></span>
                                                        </ButtonElement>
                                                        :
                                                        index !== showcaseEvents.length - 1 ? ', ' : ''
                                                    }
                                                </React.Fragment>
                                            ))}
                                            {' '}
                                            {t('showcase.goToEvent2')}
                                        </p>
                                    </>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {showcase?.owner === walletAddress ?
                    <div className="col-auto">
                        <ButtonElement link className={'p-2 mt-n1 d-md-none'} onClick={addEventHandler}>
                            <span><AddIcon/></span>
                        </ButtonElement>
                        <ButtonElement link className={'mt-n1 d-none d-md-flex'}
                                       onClick={addEventHandler}>
                            <span className="me-2"><AddIcon/></span>
                            <span>{t('button.addEvent')}</span>
                        </ButtonElement>
                    </div>
                    :
                    null
                }
            </div>
            <div className="row mt-4">
                {showcase ?
                    showcase.enableAfter < currentDate && showcase.disableAfter > currentDate ?
                        network ?
                            tokenList
                            :
                            <AlertElement centered type={'danger'}>{t('error.wrongUri')}</AlertElement>
                        :
                        <AlertElement centered type={'warning'}>{t('status.showcaseNotActive')}</AlertElement>
                    :
                    <LoadingIcon/>
                }
            </div>
        </div>
    </div>
}

export default Showcase
