import React, {useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {useConnectWallet} from '@web3-onboard/react'
import {useTranslation} from 'react-i18next'
import {WalletNotConnected} from '../static'
import {ButtonElement, InputElement} from '../elements'
import {checkInt} from '../../utils/functions'
import {AppDispatch} from '../../store/store'
import {getProfile, postUserTelegramId, requestProfile} from '../../store/profileSlice'
import {getWalletAddress, sendRequestWithAuth} from '../../store/appSlice'
import {getUser} from '../../store/authSlice'

const Profile = () => {
    const {t} = useTranslation()
    const [searchParams] = useSearchParams('')
    const tgId = searchParams.get('tgId') || ''
    const [inputTelegram, setInputTelegram] = useState('')
    const [inputTelegramError, setInputTelegramError] = useState('')
    const profile = useSelector(getProfile)
    const user = useSelector(getUser)
    const walletAddress = useSelector(getWalletAddress)

    const [{wallet}] = useConnectWallet()
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        checkInt(tgId, setInputTelegram, () => {
        })
    }, [])
    useEffect(() => {
        if (user?.auth) {
            dispatch(sendRequestWithAuth(requestProfile()))
        }
    }, [walletAddress, user])

    if (!wallet) {
        return <WalletNotConnected/>
    }

    const inputTelegramHandler = (value: string) => {
        checkInt(value, setInputTelegram, setInputTelegramError)
    }
    const saveTelegramHandler = () => {
        dispatch(sendRequestWithAuth(postUserTelegramId(inputTelegram)))
    }

    return <div className="page-content">
        <div className="container">
            <h1>Profile</h1>
            <div className="row">
                {profile && profile.telegramId > BigInt(0) ?
                    <div className="col-md-8 col-xl-5">
                        <div className="mb-3">
                            <label className="form-label d-md-block">Telegram
                                ID: {profile.telegramId.toString()}</label>
                        </div>
                    </div>
                    :
                    <>
                        <div className="col-md-8 col-xl-5">
                            <div className="mb-3">
                                <InputElement
                                    value={inputTelegram}
                                    onChange={inputTelegramHandler}
                                    errorText={inputTelegramError}
                                    error={inputTelegramError !== ''}
                                    label={'Telegram ID'}
                                />
                            </div>
                        </div>
                        <div className="col-md-2 col-xl-1">
                            <div className="mb-3">
                                <label className="form-label d-none d-md-block">&nbsp;</label>
                                <ButtonElement
                                    outline
                                    className={'w-100'}
                                    onClick={saveTelegramHandler}
                                >{t('button.save')}</ButtonElement>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    </div>
}

export default Profile
