import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {DropdownPlaceholder, DropdownSelector} from '../elements'
import {checkWallet} from '../../store/appSlice'
import {IDropdownItem} from '../../store/types'
import {
    getOnChainEvents,
    getSelectedEvent,
    requestOnChainEvents,
    setSelectedEvent
} from '../../store/onChainEventsSlice'

interface propsType {
    error?: string
}

const SelectOnchainEvent = (props: propsType) => {
    const {t} = useTranslation()
    const events = useSelector(getOnChainEvents)
    const selectedEvent = useSelector(getSelectedEvent)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!events) {
            dispatch(checkWallet({request: requestOnChainEvents()}))
        }
    }, [events])

    const eventsList: IDropdownItem[] = events?.map((item): IDropdownItem => {
        return {id: item.id, name: item.name}
    }) || []

    return <>
        <div className="d-flex align-items-center justify-content-between">
            <label className="form-label">{t('form.label.eventTitle')} <span className="text-danger">*</span></label>
        </div>
        {events ?
            <DropdownSelector
                list={eventsList}
                setItem={(v: number) => {
                    dispatch(setSelectedEvent(v))
                }}
                currentItem={selectedEvent}
                errorText={props.error}
            />
            :
            <DropdownPlaceholder title={t('status.loading')}/>
        }
    </>
}

export default SelectOnchainEvent
