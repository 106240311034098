import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import {ethers} from 'ethers'
import {AbiItem} from 'web3-utils'
import i18next from 'i18next'
import {RootState} from './store'
import {
    DBShowcase,
    ICollection, IOnchainEvent,
    IOnChainEventData,
    ISendTransaction,
    IToken,
    SliceResponse
} from './types'
import {_AssetType, API_URL, CHAINS, EVENT_FACTORY_BASE_URI} from '../utils/constants'
import {
    checkResponse,
    sendRequestWithAuth, setModalAddEventToShowcase,
    setModalCreateOnChainEvent, setModalEditOnchainEvent,
    setModalError,
    setModalSendTransactions
} from './appSlice'
import {createIpfsLink} from '../utils/functions'
import Erc721Abi from '../utils/abi/erc721.json'
import {setShowcaseEvents} from './showcaseV2Slice'

interface OnChainEventsState {
    currentOnChainEvent: IOnChainEventData | undefined | null
    eventShowcases: DBShowcase[] | null
    onChainEvents: IOnchainEvent[] | null
    selectedEvent: number | null
    userTickets: IToken[] | null
    userWnfts: IToken[] | null
}

const initialState: OnChainEventsState = {
    currentOnChainEvent: null,
    eventShowcases: null,
    onChainEvents: null,
    selectedEvent: null,
    userTickets: null,
    userWnfts: null,
}

export const addOnchainEventToShowcase = createAsyncThunk(
    'onChainEvents/addOnchainEventToShowcase',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {modalAddEventToShowcase} = state.app
        const {jwt} = state.auth
        const {selectedEvent} = state.onChainEvents

        let response: SliceResponse = {}
        if (!jwt || !selectedEvent || !modalAddEventToShowcase) {
            response.error = {text: i18next.t('error.jwtOrEventNotFound')}
        } else {
            const {contract, network, showcaseName} = modalAddEventToShowcase
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {contract, name: showcaseName, network: Number(network)}
                const result = await axios.post(`${API_URL}onchain/${selectedEvent}/showcases`, body, config)
                response.status = result.status
                response.data = null
                response.successCallback = () => {
                    dispatch(setModalAddEventToShowcase(null))
                    dispatch(setShowcaseEvents(null))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const applyOnChainTicket = createAsyncThunk(
    'onChainEvents/applyOnChainTicket',
    async (tokenId: bigint, {dispatch, getState}): Promise<boolean> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app
        const {currentOnChainEvent} = state.onChainEvents

        if (!currentNetwork || !web3 || !walletAddress || !currentOnChainEvent) {
            return false
        }

        let transactions: ISendTransaction[] = []
        try {
            const tokenContract = new web3.eth.Contract(Erc721Abi as AbiItem[], currentOnChainEvent.tickets)
            const approvedFor = (await tokenContract.methods.getApproved(tokenId).call()).toLowerCase()
            if (approvedFor !== CHAINS[currentNetwork].wrapperBatchContract) {
                const method = tokenContract.methods.approve(CHAINS[currentNetwork].wrapperBatchContract, tokenId)
                const encodedABI = method.encodeABI()
                transactions.push({
                    trx: {
                        from: walletAddress,
                        to: currentOnChainEvent.tickets,
                        data: encodedABI,
                    },
                    title: i18next.t('action.setApprovalForMintTickets'),
                })
            }

        } catch (e) {
            console.log(e)
            dispatch(setModalError({text: i18next.t('error.approvingTicket'), buttons: ['close']}))
            return false
        }
        const contract = new web3.eth.Contract(CHAINS[currentNetwork].wrapperBatchContractAbi, CHAINS[currentNetwork].wrapperBatchContract)
        const method = contract.methods.wrapIn([
            [[_AssetType.ERC721, currentOnChainEvent.tickets], tokenId.toString(), 0],
            walletAddress,
            [],
            [],
            [],
            _AssetType.ERC721,
            0,
            '0x0000'
        ], [], walletAddress, currentOnChainEvent.eventContract)
        const encodedABI = method.encodeABI()
        transactions.push({
            trx: {
                from: walletAddress,
                to: CHAINS[currentNetwork].wrapperBatchContract,
                data: encodedABI,
//                gasLimit: ethers.utils.parseUnits('0.01', 'gwei')
            },
            title: i18next.t('action.useTicket'),
            successfulSendingCallback: () => {
                dispatch(requestUserTickets())
            }
        })
        dispatch(setModalSendTransactions({transactions}))
        return true
    }
)

export const createOnChainCertificate = createAsyncThunk(
    'onChainEvents/createOnChainCertificate',
    async (tokenId: bigint, {dispatch, getState}): Promise<boolean> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app
        const {currentOnChainEvent} = state.onChainEvents

        if (!currentNetwork || !web3 || !walletAddress || !currentOnChainEvent) {
            return false
        }

        let transactions: ISendTransaction[] = []
        const contract = new web3.eth.Contract(CHAINS[currentNetwork].wrapperBatchContractAbi, CHAINS[currentNetwork].wrapperBatchContract)
        const method = contract.methods.upgradeRules([[_AssetType.ERC721, currentOnChainEvent.eventContract], tokenId.toString(), 0])
        const encodedABI = method.encodeABI()
        transactions.push({
            trx: {
                from: walletAddress,
                to: CHAINS[currentNetwork].wrapperBatchContract,
                data: encodedABI,
//                gasLimit: ethers.utils.parseUnits('0.01', 'gwei')
            },
            title: i18next.t('button.createCertificate'),
            successfulSendingCallback: () => {
                dispatch(sendRequestWithAuth(requestUserWnfts()))
            }
        })
        dispatch(setModalSendTransactions({transactions}))
        return true
    }
)

export const createOnChainEvent = createAsyncThunk(
    'onChainEvents/createOnChainEvent',
    async (
        params: {
            name: string,
            symbol: string,
            ticketContract: string,
            useFrom: number,
            useTo: number,
            createFrom: number,
            createTo: number,
        },
        {dispatch, getState}
    ): Promise<boolean> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !web3 || !walletAddress) {
            return false
        }

        let transactions: ISendTransaction[] = []
        const contract = new web3.eth.Contract(CHAINS[currentNetwork].eventsManagerContractAbi, CHAINS[currentNetwork].eventsManagerContract)
        const method = contract.methods.deployNewCollection(
            CHAINS[currentNetwork].sbtImpl721Contract,
            walletAddress,
            params.name,
            params.symbol,
            EVENT_FACTORY_BASE_URI,
            CHAINS[currentNetwork].wrapperBatchContract,
            [[params.useFrom, params.useTo], [params.createFrom, params.createTo], '0x0005', params.ticketContract],
        )
        const encodedABI = method.encodeABI()
        transactions.push({
            trx: {
                from: walletAddress,
                to: CHAINS[currentNetwork].eventsManagerContract,
                data: encodedABI,
            },
            title: i18next.t('button.createEvent'),
            successfulSendingCallback: (receipt) => {
                dispatch(setOnChainEvents(null))
                dispatch(setModalCreateOnChainEvent(false))
                const contractAddress = receipt?.logs[0].address.toLowerCase()
                if (contractAddress) {
                    dispatch(sendRequestWithAuth(postOnchainEvent({contractAddress})))
                }
            }
        })
        dispatch(setModalSendTransactions({transactions}))
        return true
    }
)

export const editOnChainEventDates = createAsyncThunk(
    'onChainEvents/editOnChainEventDates',
    async (
        params: {
            contract: string,
            ticketContract: string,
            useFrom: number,
            useTo: number,
            createFrom: number,
            createTo: number,
        },
        {dispatch, getState}
    ): Promise<boolean> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !web3 || !walletAddress) {
            return false
        }

        let transactions: ISendTransaction[] = []
        const contract = new web3.eth.Contract(CHAINS[currentNetwork].eventsManagerContractAbi, CHAINS[currentNetwork].eventsManagerContract)
        const method = contract.methods.editDatesForEvent(
            params.contract,
            [[params.useFrom, params.useTo], [params.createFrom, params.createTo], '0x0005', params.ticketContract],
        )
        const encodedABI = method.encodeABI()
        transactions.push({
            trx: {
                from: walletAddress,
                to: CHAINS[currentNetwork].eventsManagerContract,
                data: encodedABI,
            },
            title: i18next.t('button.editEvent'),
            successfulSendingCallback: () => {
                dispatch(requestOnChainEvent({network: currentNetwork, address: params.contract}))
                dispatch(setModalEditOnchainEvent(''))
            }
        })
        dispatch(setModalSendTransactions({transactions}))
        return true
    }
)

export const postOnchainEvent = createAsyncThunk(
    'onChainEvents/postOnchainEvent',
    async (
        params: Required<Pick<ICollection, 'contractAddress'>>,
        {getState, dispatch}
    ): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork} = state.app
        const {jwt} = state.auth
        const {selectedOrganizerId} = state.organizers

        let response: SliceResponse = {}
        if (!jwt || !selectedOrganizerId) {
            response.error = {text: i18next.t('error.jwtUserOrOrganizerNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.post(`${API_URL}collections/onchain/${selectedOrganizerId}`, {
                    address: params.contractAddress,
                    network: Number(currentNetwork),
                }, config)
                response.status = result.status
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.afterCheckCallback = () => dispatch(setOnChainEvents(null))
        dispatch(checkResponse(response))
    }
)

export const requestOnChainEvent = createAsyncThunk(
    'onChainEvents/requestOnChainEvent',
    async (
        {network, address}: { network: string, address: string },
        {getState}
    ): Promise<IOnChainEventData | undefined | null> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !walletAddress || !web3) {
            return null
        }

        if (isNaN(Number(network)) || network !== currentNetwork || !ethers.utils.isAddress(address)) {
            return null
        }

        try {
            const contract = new web3.eth.Contract(CHAINS[currentNetwork].eventsManagerContractAbi, CHAINS[currentNetwork].eventsManagerContract)
            const eventData = await contract.methods.getDataForEvent(address).call()
            const cntr = new web3.eth.Contract(CHAINS[currentNetwork].sbtImpl721ContractAbi, address)
            const name = await cntr.methods.name().call()
            const symbol = await cntr.methods.symbol().call()
            const owner = (await cntr.methods.owner().call()).toLowerCase()
            return {
                certificate: {finish: Number(eventData.certificate.finish), start: Number(eventData.certificate.start)},
                eventContract: address,
                eventName: name,
                eventTicker: symbol,
                owner,
                sbtRules: eventData.sbtRules,
                tickets: eventData.tickets.toLowerCase(),
                useTicket: {finish: Number(eventData.useTicket.finish), start: Number(eventData.useTicket.start)},
            }
        } catch (e) {
            console.log(e)
        }
        return undefined
    }
)

export const requestOnChainEvents = createAsyncThunk(
    'onChainEvents/requestUserTickets',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork} = state.app
        const {jwt} = state.auth
        const {selectedOrganizerId} = state.organizers

        let response: SliceResponse = {}
        if (!currentNetwork) {
            response.error = {text: i18next.t('error.networkNotSelected')}
        } else if (!jwt || !selectedOrganizerId) {
            response.error = {text: i18next.t('error.jwtOrOrganizerNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.get(
                    `${API_URL}collections/onchain/${currentNetwork}/${selectedOrganizerId}`,
                    config
                )
                let events: IOnchainEvent[] = []
                for (let item of result.data.events) {
                    events.push({
                        assetType: _AssetType.ERC721,
                        createdAt: Number(item.createdAt),
                        contractAddress: item.contract,
                        id: item.id,
                        name: item.name,
                        owner: item.owner.toLowerCase(),
                        showcaseType: item.showcaseType,
                    })
                }
                response.status = result.status
                response.data = events.sort((a, b) => (a.createdAt - b.createdAt))
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setOnChainEvents(value))
        }
        dispatch(checkResponse(response))
    }
)

/*
export const requestOnChainEvents = createAsyncThunk(
    'onChainEvents/requestOnChainEvents',
    async (_, {getState}): Promise<ICollection[] | null> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !walletAddress || !web3) {
            return null
        }

        try {
            const contract = new web3.eth.Contract(CHAINS[currentNetwork].eventsManagerContractAbi, CHAINS[currentNetwork].eventsManagerContract)
            const result = await contract.methods.getUsersCollections(walletAddress).call()
            let events: ICollection[] = []
            for (let item of result) {
                if (Number(item.assetType) !== _AssetType.ERC721) {
                    continue
                }

                const event = await contract.methods.getDataForEvent(item.contractAddress).call()
                if (event && event.tickets === NULL_ADDRESS) {
                    continue
                }

                const cntr = new web3.eth.Contract(CHAINS[currentNetwork].sbtImpl721ContractAbi, item.contractAddress)
                const name = await cntr.methods.name().call()
                events.push({
                    assetType: Number(item.assetType),
                    contractAddress: item.contractAddress.toLowerCase(),
                    name,
                    //todo: set createdAt and showcaseType
                    createdAt: 0,
                    showcaseType: 'Classic',
                })
            }
            return events
        } catch (e) {
            console.log(e)
        }
        return []
    }
)
*/

export const requestOnChainEventShowcases = createAsyncThunk(
    'onChainEvents/requestOnChainEventShowcases',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork} = state.app
        const {jwt} = state.auth
        const {currentOnChainEvent} = state.onChainEvents

        let response: SliceResponse = {}
        if (!currentNetwork) {
            response.error = {text: i18next.t('error.networkNotSelected')}
        } else if (!jwt || !currentOnChainEvent) {
            response.error = {text: i18next.t('error.jwtOrEventNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.get(
                    `${API_URL}events/onchain/${Number(currentNetwork)}/${currentOnChainEvent.eventContract.toLowerCase()}/showcases`,
                    config
                )
                let showcases: DBShowcase[] = []
                for (let item of result.data.showcases) {
                    showcases.push({
                        chain: Number(item.network),
                        contract: item.contract,
                        id: item.id,
                        name: item.name,
                        eventId: item.eventId,
                        title: item.title,
                    })
                }
                response.status = result.status
                response.data = showcases
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setEventShowcases(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestUserTickets = createAsyncThunk(
    'onChainEvents/requestUserTickets',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress} = state.app
        const {currentOnChainEvent} = state.onChainEvents

        let response: SliceResponse = {}
        if (!currentNetwork || !walletAddress || !currentOnChainEvent) {
            response.error = {text: i18next.t('error.networkOrWalletNotSelected')}
        } else {
            try {
                const result = await axios.get(`${API_URL}tokens/${Number(currentNetwork)}/${walletAddress}/${currentOnChainEvent.tickets}`)
                let list: IToken[] = []
                for (let item of result.data.tokens) {
                    list.push({
                        assetType: item.assetType,
                        network: currentNetwork,
                        contract: item.contract.toLowerCase(),
                        tokenId: item.tokenId,
                        tokenUri: createIpfsLink(item.tokenUri),
                        owner: item.owner.toLowerCase(),
                        rules: item.rules !== null ? Number(item.rules) : NaN,
                        blockNum: Number(item.blockNum),
                    })
                }
                response.status = result.status
                response.data = list
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setUserTickets(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestUserWnfts = createAsyncThunk(
    'onChainEvents/requestUserWnfts',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress} = state.app
        const {currentOnChainEvent} = state.onChainEvents

        let response: SliceResponse = {}
        if (!currentNetwork || !walletAddress || !currentOnChainEvent) {
            response.error = {text: i18next.t('error.networkWalletOrEventNotSelected')}
        } else {
            try {
                const result = await axios.get(`${API_URL}oracle/wnft/721/user/${Number(currentNetwork)}/${walletAddress}/${currentOnChainEvent.eventContract}`)
                let list: IToken[] = []
                for (let item of result.data.tokens) {
                    list.push({
                        assetType: item.assetType,
                        network: currentNetwork,
                        contract: item.contract.toLowerCase(),
                        tokenId: item.tokenId,
                        tokenUri: createIpfsLink(item.tokenUri),
                        owner: item.owner.toLowerCase(),
                        blockNum: Number(item.blockNum),
                        rules: Number(item.rules),
                    })
                }
                response.status = result.status
                response.data = list
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setUserWnfts(value))
        }
        dispatch(checkResponse(response))
    }
)

export const onChainEventsSlice = createSlice({
    name: 'onChainEvents',
    initialState,
    reducers: {
        resetState: (state) => {
            let key: keyof OnChainEventsState
            for (key in initialState) {
                Reflect.set(state, key, initialState[key])
            }
        },
        setCurrentOnChainEvent: (state, action: PayloadAction<IOnChainEventData | undefined | null>) => {
            state.currentOnChainEvent = action.payload
        },
        setEventShowcases: (state, action: PayloadAction<DBShowcase[] | null>) => {
            state.eventShowcases = action.payload
        },
        setOnChainEvents: (state, action: PayloadAction<IOnchainEvent[] | null>) => {
            state.onChainEvents = action.payload
        },
        setSelectedEvent: (state, action: PayloadAction<number | null>) => {
            state.selectedEvent = action.payload
        },
        setUserTickets: (state, action: PayloadAction<IToken[] | null>) => {
            state.userTickets = action.payload
        },
        setUserWnfts: (state, action: PayloadAction<IToken[] | null>) => {
            state.userWnfts = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestOnChainEvent.fulfilled, (state, action: PayloadAction<IOnChainEventData | undefined | null>) => {
            state.currentOnChainEvent = action.payload
        })
/*
        builder.addCase(requestOnChainEvents.fulfilled, (state, action: PayloadAction<ICollection[] | null>) => {
            state.onChainEvents = action.payload
        })
*/
    },
})

export const getCurrentOnChainEvent = (state: RootState): IOnChainEventData | undefined | null => state.onChainEvents.currentOnChainEvent
export const getEventShowcases = (state: RootState): DBShowcase[] | null => state.onChainEvents.eventShowcases
export const getOnChainEvents = (state: RootState): IOnchainEvent[] | null => state.onChainEvents.onChainEvents
export const getSelectedEvent = (state: RootState): number | null => state.onChainEvents.selectedEvent
export const getUserTickets = (state: RootState): IToken[] | null => state.onChainEvents.userTickets
export const getUserSbts = (state: RootState): IToken[] => state.onChainEvents.userWnfts?.filter(item => item.rules === 5) || []
export const getUserUsedTickets = (state: RootState): IToken[] => state.onChainEvents.userWnfts?.filter(item => item.rules === 0) || []
export const getUserWnfts = (state: RootState): IToken[] | null => state.onChainEvents.userWnfts

export const {
    resetState,
    setCurrentOnChainEvent,
    setEventShowcases,
    setOnChainEvents,
    setSelectedEvent,
    setUserTickets,
    setUserWnfts,
} = onChainEventsSlice.actions

export default onChainEventsSlice.reducer
