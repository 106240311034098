import React from 'react'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {signUserNonce} from '../../store/authSlice'
import {AppDispatch} from '../../store/store'
import {ButtonElement} from '../elements'

const SignAuthMessage = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch<AppDispatch>()

    const signMessage = () => {
        dispatch(signUserNonce())
    }

    return <div className={'s-header__sign-auth me-3 me-md-4'}>
        <ButtonElement type={'secondary'} small outline className={'btn-auth'} onClick={signMessage}>
            {t('button.signAuthMessage')}
        </ButtonElement>
    </div>
}

export default SignAuthMessage
