import React, {ReactNode, useState} from 'react'
import {ButtonElement} from '../elements'
import {ChevronDownIcon} from '../icons'
import {IProperty} from '../../store/types'

interface PropsType {
    children: ReactNode
    description?: IProperty[]
    opened?: boolean
    openHandler?: (v: boolean) => void
    title: string
}

const AdvancedSettingsBlock = (props: PropsType) => {
    const [advancedOpened, setAdvancedOpened] = useState(false)
    const opened = props.opened === undefined ? advancedOpened : props.opened
    const openHandler: (v: boolean) => void = props.openHandler === undefined ? setAdvancedOpened : props.openHandler

    return <div className="mb-6">
        <div className="row align-items-center mb-3 gx-3">
            {props.description ?
                <div className="col-sm mb-2 mb-sm-0 order-sm-2">
                    {props.description.map((item, index) => (
                        <small key={index}> <span className="text-muted">{item.trait_type} </span>{item.value}</small>
                    ))}
                </div>
                :
                null
            }
            <div className="col-sm-auto order-sm-1">
                <ButtonElement
                    small
                    outline
                    className={`${opened ? 'collapse-opened' : 'dropdown-toggle'}`}
                    onClick={() => {
                        openHandler(!opened)
                    }}
                >
                    <span className="me-2">{props.title}</span>
                    <ChevronDownIcon/>
                </ButtonElement>
            </div>
        </div>
        <div className={`mb-4 collapse ${opened ? 'show' : ''}`}>
            <div className="collapse-content">
                {props.children}
            </div>
        </div>
    </div>
}

export default AdvancedSettingsBlock
