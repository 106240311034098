import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
    getCurrentNetwork,
    getModalSendTransactions,
    setModalSendTransactions,
    sendTransaction, setRedirectPath,
} from '../../store/appSlice'
import {CHAINS} from '../../utils/constants'
import {AppDispatch} from '../../store/store'
import {ExternalLinkIcon, SuccessIcon} from '../icons'
import {ButtonElement} from '../elements'

interface propsType {
    show: boolean
}

const ModalSendTransactions = (props: propsType) => {
    const {t} = useTranslation()
    const currentNetwork = useSelector(getCurrentNetwork)
    const modal = useSelector(getModalSendTransactions)
    const [currentTransaction, setCurrentTransaction] = useState(0)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (modal) {
            if (currentTransaction < modal.transactions.length) {
                if (!modal.transactions[currentTransaction].signedStatus) {
                    dispatch(sendTransaction(currentTransaction))
                } else if (modal.transactions[currentTransaction].trxId) {
                    setCurrentTransaction(currentTransaction + 1)
                }
            } else {
                if (modal.redirect) {
                    const timer = setTimeout(() => {
                        dispatch(setRedirectPath(modal.redirect?.path || ''))
                    }, modal.redirect.time)
                    return () => {
                        clearTimeout(timer)
                    }
                }
            }
        }
    }, [modal, currentTransaction])

    const closeModal = () => {
        dispatch(setModalSendTransactions(null))
    }

    if (!currentNetwork || !modal) {
        closeModal()
        return null
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        tabIndex={-1}
        aria-labelledby="Progress steps"
        aria-hidden="true"
        style={{display: 'block'}}
    >
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header pb-3">
                    <h2 className="modal-title">{modal.title || t('modal.title.sendingTransactions')}</h2>
                </div>
                <div className="modal-body">
                    <div>
                        {currentTransaction >= modal.transactions.length ?
                            modal.successText
                            :
                            null
                        }
                    </div>
                    <div>
                        {modal?.transactions.map((item, index) => (
                            <div className="progress-step" key={`transactionRow${index}`}>
                                <div className="row align-items-center">
                                    <div className={`col ${currentTransaction < index ? 'text-muted' : ''}`}>
                                        {item.title}
                                        {currentTransaction === index ?
                                            item.signedStatus ? ` (${t('status.sending')}...)` : ` (${t('status.signing')}...)`
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="col-auto">
                                        {currentTransaction > index ? <SuccessIcon className={'icon-success'}/> : null}
                                        {currentTransaction === index ?
                                            <div className="spinner-border text-warning" role="status">
                                                <span className="visually-hidden">{t('status.loading')}...</span>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="modal-footer justify-content-between">
                    <div className="col-12 col-lg mb-3 mb-lg-0">
                        {modal?.transactions.map((item, index) => (
                            <React.Fragment key={index}>
                                {item.trxId ?
                                    <>
                                        <a
                                            className="icon-link"
                                            href={`${CHAINS[currentNetwork].blockExplorer}/tx/${item.trxId}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <span>{t('word.transaction')}</span>
                                            <ExternalLinkIcon/>
                                        </a>
                                        <br/>
                                    </>
                                    :
                                    null
                                }
                            </React.Fragment>
                        ))}
                    </div>
                    {currentTransaction >= modal.transactions.length ?
                        <div className="col-12 col-lg-auto">
                            <div className="row gx-3">
                                <div className="col-sm-6 col-lg-auto order-md-2 mb-3 mb-lg-0">
                                    <ButtonElement
                                        className={'w-100'}
                                        onClick={() => {
                                            modal.successButton?.action?.()
                                            closeModal()
                                        }}
                                    >{modal.successButton?.title || t('button.continue')}</ButtonElement>
                                </div>
                                {modal.actionButton ?
                                    <div className="col-sm-6 col-lg-auto order-md-1 mb-3 mb-lg-0">
                                        <ButtonElement
                                            outline
                                            type={'secondary'}
                                            className={'w-100'}
                                            onClick={() => {
                                                modal.actionButton?.action()
                                                closeModal()
                                            }}
                                        >{modal.actionButton.title}</ButtonElement>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    </div>
}

export default ModalSendTransactions
