import React, {ReactNode, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {ChevronDownIcon} from '../icons'
import {ButtonElement, LabelElement} from './index'
import {getCurrentNetwork} from '../../store/appSlice'
import {IDropdownItem} from '../../store/types'

interface IProps {
    currentItem?: any
    button?: boolean
    error?: boolean
    errorText?: string
    firstItem?: IDropdownItem
    firstItemAction?: () => void
    label?: ReactNode
    list: IDropdownItem[]
    setItem: (id: any) => void
    title?: string
}

const DropdownSelector = (props: IProps) => {
    const {t} = useTranslation()
    const [menuOpened, setMenuOpened] = useState(false)
    const currentNetwork = useSelector(getCurrentNetwork)
    let currentItem: any = null
    for (let item of props.list || []) {
        if (item.id === props.currentItem) {
            currentItem = item.name
        }
    }

    const closeMenu = () => {
        const body = document.querySelector('body')
        if (body) {
            body.onclick = null
        }
        setMenuOpened(false)
    }
    const openMenu = () => {
        if (menuOpened) {
            return
        }

        setTimeout(() => {
            const body = document.querySelector('body')
            if (body) {
                body.onclick = closeMenu
            }
        }, 100)
        setMenuOpened(true)
    }

    useEffect(() => {
        closeMenu()
    }, [currentNetwork])

    const error = props.errorText && props.errorText !== '' ? true : props.error

    return <>
        {props.label ? <LabelElement>{props.label}</LabelElement> : null}
        <div className="dropdown">
            {props.button ?
                <ButtonElement
                    small
                    outline
                    className={'btn-filter-select dropdown-toggle w-100'}
                    onClick={openMenu}
                >
                    <span className="text-truncate me-2">
                        {currentItem || props.title || (props.list.length > 0 ?
                            t('element.notSelected')
                            :
                            t('status.emptyList')
                        )}
                    </span>
                    <ChevronDownIcon/>
                </ButtonElement>
                :
                <div
                    className={`form-control form-select dropdown-toggle ${menuOpened ? 'show' : ''} ${error ? 'is-invalid' : ''}`}
                    onClick={openMenu}
                >
                    <div className={`value ${props.list.length === 0 ? 'placeholder' : ''}`}>
                        {currentItem || props.title || (props.list.length > 0 ?
                            t('element.notSelected')
                            :
                            t('status.emptyList')
                        )}
                    </div>
                    <ChevronDownIcon/>
                </div>
            }
            {props.list.length > 0 || props.firstItem ?
                <ul className={`dropdown-menu is-scrollable ${menuOpened ? 'show' : ''}`}>
                    {props.firstItem ?
                        <li key={props.firstItem.id}>
                            <ButtonElement
                                link
                                className={'w-100 justify-content-start'}
                                onClick={() => {
                                    props.firstItemAction?.()
                                    closeMenu()
                                }}
                            >{props.firstItem.name}</ButtonElement>
                        </li>
                        :
                        null
                    }
                    {props.list.map((item, index) => {
                        return <li
                            key={index}
                            className={`dropdown-item ${item.id === props.currentItem ? 'active' : ''}`}
                            onClick={() => {
                                props.setItem(item.id)
                                closeMenu()
                            }}
                        >{item.name}</li>
                    })}
                </ul>
                :
                null
            }
            {props.errorText && props.errorText !== '' ?
                <div className="invalid-feedback">{props.errorText}</div>
                :
                null
            }
        </div>
    </>
}

export default DropdownSelector
