import React, {useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, NavLink, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {CHAINS} from '../../utils/constants'
import {NotFound} from '../static'
import {
    getCurrentOnChainEvent, getEventShowcases, getUserSbts, getUserTickets, getUserUsedTickets,
    requestOnChainEvent, requestOnChainEventShowcases, requestUserTickets,
    requestUserWnfts,
    setCurrentOnChainEvent, setUserTickets, setUserWnfts
} from '../../store/onChainEventsSlice'
import {getCurrentNetwork, getWalletAddress, setModalEditOnchainEvent} from '../../store/appSlice'
import {AlertElement, ButtonElement, CopyToClipboardBlock} from '../elements'
import {OnChainTicketRow} from '../tickets'
import {compactString} from '../../utils/functions'
import {EditIcon, LoadingIcon, TagIcon} from '../icons'

const OnChainEvent = () => {
    const {t, i18n} = useTranslation()
    let {network, contractAddress} = useParams()
    network = `0x${Number(network).toString(16)}`
    if (!CHAINS[network]) {
        network = undefined
    }
    const [activeTab, setActiveTab] = useState(0)
    const [loadedEvent, setLoadedEvent] = useState(false)
    const [loadingTickets, setLoadingTickets] = useState(false)
    const currentNetwork = useSelector(getCurrentNetwork)
    const event = useSelector(getCurrentOnChainEvent)
    const showcases = useSelector(getEventShowcases)
    const userSbts = useSelector(getUserSbts, shallowEqual)
    const userTickets = useSelector(getUserTickets)
    const userUsedTickets = useSelector(getUserUsedTickets, shallowEqual)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        const timer = setInterval(() => {
            setLoadingTickets(true)
        }, 15000)
        return () => {
            clearInterval(timer)
            dispatch(setUserTickets(null))
            dispatch(setUserWnfts(null))
        }
    }, [])
    useEffect(() => {
        if (loadingTickets) {
            if (currentNetwork && walletAddress && event) {
                dispatch(requestUserTickets())
                dispatch(requestUserWnfts())
            } else {
                setLoadingTickets(false)
            }
        }
    }, [loadingTickets])
    useEffect(() => {
        if (userTickets) {
            setLoadingTickets(false)
        }
    }, [userTickets])
    useEffect(() => {
        if (contractAddress && network) {
            dispatch(requestOnChainEvent({network, address: contractAddress}))
        }
        return () => {
            dispatch(setCurrentOnChainEvent(null))
        }
    }, [contractAddress, currentNetwork, network])
    useEffect(() => {
        if (currentNetwork && event && walletAddress && !loadingTickets) {
            setLoadingTickets(true)
        }
        return () => {
            dispatch(setUserTickets(null))
            dispatch(setUserWnfts(null))
        }
    }, [currentNetwork, event, walletAddress])
    useEffect(() => {
        if (loadedEvent && !event && contractAddress) {
            setLoadedEvent(false)
            dispatch(requestOnChainEvent({network: network || '', address: contractAddress}))
        } else if (!loadedEvent && event) {
            setLoadedEvent(true)
            dispatch(requestOnChainEventShowcases())
        }
    }, [event])

    const ticketsList = useMemo(() => {
        if (!userTickets) {
            return <LoadingIcon/>
        }

        return userTickets.length > 0 ? userTickets.map((item, index) => (
            <OnChainTicketRow item={item} key={index}/>
        )) : <AlertElement centered>{t('error.ticketsNotFound')}</AlertElement>
    }, [userTickets, i18n.language])
    const usedTicketsList = useMemo(() => {
        if (!userUsedTickets) {
            return <LoadingIcon/>
        }

        return userUsedTickets.length > 0 ? userUsedTickets.map((item, index) => (
            <OnChainTicketRow item={item} key={index}/>
        )) : <AlertElement centered>{t('error.ticketsNotFound')}</AlertElement>
    }, [userUsedTickets, i18n.language])
    const sbtsList = useMemo(() => {
        if (!userSbts) {
            return <LoadingIcon/>
        }

        return userSbts.length > 0 ? userSbts.map((item, index) => (
            <OnChainTicketRow item={item} key={index}/>
        )) : <AlertElement centered>{t('error.sbtsNotFound')}</AlertElement>
    }, [userSbts, i18n.language])
    const showcasesList = useMemo(() => {
        if (!showcases) {
            return null
        }

        return showcases.map((item) => (
            <Link key={item.id}
                  to={`/showcases/${item.chain}/${item.title}`}
                  className="btn btn-link w-100 justify-content-start"
            >
                <TagIcon className={'me-3'}/>
                <span> <b>{item.title}</b></span>
            </Link>
        ))
    }, [showcases])

    const editEventHandler = () => {
        dispatch(setModalEditOnchainEvent(contractAddress || ''))
    }

    if (!contractAddress || !network) {
        return <NotFound/>
    }

    return <div className="page-content">
        <div className="container">
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <NavLink to={'/onchain/events'}>{t('section.myEvents')}</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        {event?.eventName || contractAddress}
                    </li>
                </ol>
            </nav>
        </div>
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1 className="mb-3">{event?.eventName || contractAddress}</h1>
                </div>
                {event?.owner === walletAddress ?
                    <div className="col-auto">
                        <ButtonElement
                            link
                            className={'p-2 mt-n2 d-md-none'}
                            onClick={editEventHandler}
                        >
                            <span><EditIcon/></span>
                        </ButtonElement>
                        <ButtonElement
                            link
                            className={'mt-n1 d-none d-md-flex px-0'}
                            onClick={editEventHandler}
                        >
                            <span className="me-2"><EditIcon/></span>
                            <span>{t('button.edit')}</span>
                        </ButtonElement>
                    </div>
                    :
                    null
                }
            </div>
            {event !== null ?
                event ?
                    <>
                        <div className="mb-4 pb-2 mb-lg-2 pb-lg-0">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <span className="text-muted me-2">{t('word.contract')}:</span>
                                    <span>{compactString(contractAddress)}</span>
                                    <CopyToClipboardBlock text={contractAddress}/>
                                </div>
                                <div className="col-auto">
                                    <span className="text-muted me-2">{t('word.ticker')}:</span>
                                    <span>{event.eventTicker}</span>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <h3 className="mb-3">{t('event.buyTicketsHere')}</h3>
                            <div className="row-table">
                                {showcasesList}
                            </div>
                        </div>
                        <ul className="nav nav-tabs mt-lg-6" id="myEvent" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
                                    id="tickets-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#tickets-tab-pane"
                                    type="button"
                                    role="tab"
                                    aria-controls="home-tab-pane"
                                    aria-selected={activeTab === 0}
                                    onClick={() => setActiveTab(0)}
                                >
                                    {t('button.tickets')}
                                    {loadingTickets && activeTab === 0 ?
                                        <span className={'icon-loading-container'} style={{display: 'block'}}>
                                            <LoadingIcon/>
                                        </span>
                                        :
                                        null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                                    id="used-tickets-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#used-tickets-tab-pane"
                                    type="button"
                                    role="tab"
                                    aria-controls="profile-tab-pane"
                                    aria-selected={activeTab === 1}
                                    onClick={() => setActiveTab(1)}
                                >
                                    {t('button.usedTickets')}
                                    {loadingTickets && activeTab === 1 ?
                                        <span className={'icon-loading-container'} style={{display: 'block'}}>
                                            <LoadingIcon/>
                                        </span>
                                        :
                                        null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
                                    id="certificates-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#certificates-tab-pane"
                                    type="button"
                                    role="tab"
                                    aria-controls="contact-tab-pane"
                                    aria-selected={activeTab === 2}
                                    onClick={() => setActiveTab(2)}
                                >
                                    {t('button.certificates')}
                                    {loadingTickets && activeTab === 2 ?
                                        <span className={'icon-loading-container'} style={{display: 'block'}}>
                                            <LoadingIcon/>
                                        </span>
                                        :
                                        null
                                    }
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myEventContent">
                            <div
                                className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`}
                                id="tickets-tab-pane"
                                role="tabpanel"
                                aria-labelledby="tickets-tab"
                                tabIndex={0}
                            >
                                <div className="pt-4">
                                    <div className="mb-4">
                                        <span className="text-muted me-2">{t('event.useDates')}:</span>
                                        <span>
                                            {(new Date(event.useTicket.start * 1000)).toDateString()}
                                            {' - '}
                                            {(new Date(event.useTicket.finish * 1000)).toDateString()}
                                        </span>
                                    </div>
                                    <AlertElement additionalClass={'mb-2'}>
                                        {t('alert.loadingTickets')}
                                    </AlertElement>
                                    <div className="row-table">
                                        {ticketsList}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`}
                                id="used-tickets-tab-pane"
                                role="tabpanel"
                                aria-labelledby="used-tickets-tab"
                                tabIndex={0}
                            >
                                <div className="pt-4">
                                    <div className="mb-4">
                                        <span className="text-muted me-2">{t('event.createDates')}:</span>
                                        <span>
                                            {(new Date(event.certificate.start * 1000)).toDateString()}
                                            {' - '}
                                            {(new Date(event.certificate.finish * 1000)).toDateString()}
                                        </span>
                                    </div>
                                    <AlertElement additionalClass={'mb-2'}>
                                        {t('alert.loadingTickets')}
                                    </AlertElement>
                                    <div className="row-table">
                                        {usedTicketsList}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`}
                                id="certificates-tab-pane"
                                role="tabpanel"
                                aria-labelledby="certificates-tab"
                                tabIndex={0}
                            >
                                <div className="pt-4">
                                    <AlertElement additionalClass={'mb-2'}>
                                        {t('alert.loadingCertificates')}
                                    </AlertElement>
                                    <div className="row-table">
                                        {sbtsList}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
                :
                <LoadingIcon/>
            }
        </div>
    </div>
}

export default OnChainEvent
