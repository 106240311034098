import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import {ethers} from 'ethers'
import {TransactionRequest} from '@ethersproject/abstract-provider'
import i18next from 'i18next'
import {AbiItem} from 'web3-utils'
import {RootState} from './store'
import {
    ICollection, IGeneratedImage,
    IOnChainEventData,
    ISendTransaction, IToken, ITransaction,
    SliceResponse
} from './types'
import {
    _AssetType,
    API_URL,
    CHAINS, CUSTOM_DROPDOWN_ITEM,
    GIFT_FACTORY_BASE_URI,
    NULL_ADDRESS,
} from '../utils/constants'
import {
    checkResponse,
    setModalCreateGiftsCollection,
    setModalError, setModalGenerateAIGift, setModalMintGifts,
    setModalSendTransactions, setSelectedGiftCollection, setSelectedGiftCollectionError
} from './appSlice'
import {createIpfsLink, getPublicCollectionDropdown, saveMetadata} from '../utils/functions'
import {resetMintState, setMintedTokenId, setNftJsonUrl, setNftTxId, setNftTxSigned} from './mintSlice'
import {
    resetImage,
    resetState as resetInputState,
    setDescription, setDescriptionError,
    setExternalUrl,
    setImageUrlError,
    setTitle, setTitleError
} from './inputSlice'

import Erc20Abi from '../utils/abi/erc20.json'
import {GATracker} from '../components/GATracker'

interface GiftsState {
    approveCoinCount: number | null
    approvedCoinCount: number
    approvedNfts: boolean
    currentGiftsCollection: IOnChainEventData | undefined | null
    generatingImage: boolean
    giftsCollections: ICollection[] | null
    giftsTxId: string | null
    mintedGiftsCount: number | null
    mintedGiftsIds: number[] | null
    needApproveNfts: boolean
    userWnfts: IToken[] | null
}

const initialState: GiftsState = {
    approveCoinCount: null,
    approvedCoinCount: 0,
    approvedNfts: false,
    currentGiftsCollection: null,
    generatingImage: false,
    giftsCollections: null,
    giftsTxId: null,
    mintedGiftsCount: null,
    mintedGiftsIds: null,
    needApproveNfts: false,
    userWnfts: null,
}

export const createGiftsCollection = createAsyncThunk(
    'gifts/createGiftsCollection',
    async (
        params: {
            name: string,
            symbol: string,
        },
        {dispatch, getState}
    ): Promise<boolean> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !web3 || !walletAddress) {
            return false
        }

        let transactions: ISendTransaction[] = []
        const contract = new web3.eth.Contract(CHAINS[currentNetwork].eventsManagerContractAbi, CHAINS[currentNetwork].eventsManagerContract)
        const method = contract.methods.deployNewCollection(
            CHAINS[currentNetwork].sbtImpl721Contract,
            walletAddress,
            params.name,
            params.symbol,
            GIFT_FACTORY_BASE_URI,
            CHAINS[currentNetwork].wrapperBatchContract,
        )
        const encodedABI = method.encodeABI()
        transactions.push({
            trx: {
                from: walletAddress,
                to: CHAINS[currentNetwork].eventsManagerContract,
                data: encodedABI,
            },
            title: i18next.t('button.createCollection'),
            successfulSendingCallback: (receipt) => {
                dispatch(requestGiftsCollections())
                if (receipt?.logs[0].address) {
                    dispatch(setSelectedGiftCollection(receipt.logs[0].address.toLowerCase()))
                }
                dispatch(setModalCreateGiftsCollection(false))
            }
        })
        dispatch(setModalSendTransactions({transactions}))
        return true
    }
)

export const generateImage = createAsyncThunk(
    'gifts/generateImage',
    async (
        params: {image: boolean, metadata: boolean, prompt: string},
        {dispatch, getState}
    ): Promise<void> => {
        const {image, metadata, prompt} = params
        const state = getState() as RootState
        const {modalGenerateAIGift} = state.app

        if ((!image && !metadata) || !modalGenerateAIGift) {
            return
        }

        let response: SliceResponse = {}
        try {
            const body = {image, metadata, prompt, template: modalGenerateAIGift.template}
            const result = await axios.post(`${API_URL}ai/image`, body)
            response.status = result.status
            let data: IGeneratedImage = {
                description: result.data.description,
                title: result.data.title,
                url: result.data.url,
            }
            response.data = data
        } catch (e: any) {
            response.defaultData = null
            if (e.response) {
                response.status = e.response.status
                response.error = {text: e.response.data.error}
            } else {
                response.error = {text: e.message}
            }
        }
        response.setData = (value: IGeneratedImage | null) => {
            dispatch(setModalGenerateAIGift(null))
            if (!value) {
                return
            }

            if (value.description !== '') {
                dispatch(setDescription(value.description))
                dispatch(setDescriptionError(null))
            }
            if (value.title !== '') {
                dispatch(setTitle(value.title))
                dispatch(setTitleError(null))
            }
            if (value.url !== '') {
                dispatch(setImageUrlError(null))
                dispatch(setExternalUrl(value.url))
            }
        }
        dispatch(checkResponse(response))
    }
)

export const mintGifts = createAsyncThunk(
    'gifts/mintGifts',
    async (_, {dispatch, getState}): Promise<boolean> => {
        const showError = (title: string, text: string) => {
            dispatch(setModalError({title, text, buttons: ['close']}))
            dispatch(setModalMintGifts(false))
            dispatch(resetMintState())
            dispatch(resetMintGiftsState())
            dispatch(setSelectedGiftCollectionError(null))
        }

        const state = getState() as RootState
        const {
            collaterals,
            description,
            externalUrl,
            loadedImage,
            properties,
            recipients,
            title,
        } = state.input
        const {jwt} = state.auth
        const {currentNetwork, selectedGiftCollection, signer, walletAddress, web3} = state.app
        const batch = recipients.value.length
        const publicDropdown = getPublicCollectionDropdown()
        if (!currentNetwork || !signer || !web3 || !walletAddress || !batch || !selectedGiftCollection || !jwt) {
            return false
        }

        const contract = new web3.eth.Contract(CHAINS[currentNetwork].nftMinterContract721Abi, CHAINS[currentNetwork].nftMinterContract721)
        let approvingAddress: string
        let wrapperContract
        if (selectedGiftCollection === publicDropdown.id) {
            wrapperContract = new web3.eth.Contract(CHAINS[currentNetwork].wrapperBatchPublicContractAbi, CHAINS[currentNetwork].wrapperBatchPublicContract)
            approvingAddress = await wrapperContract.methods.trustedWrapper().call()
        } else {
            wrapperContract = new web3.eth.Contract(CHAINS[currentNetwork].wrapperBatchContractAbi, CHAINS[currentNetwork].wrapperBatchContract)
            approvingAddress = CHAINS[currentNetwork].wrapperBatchContract
        }
        const approvedForAll = await contract.methods.isApprovedForAll(walletAddress, approvingAddress).call()
        dispatch(setNeedApproveNfts(!approvedForAll))
        dispatch(setModalMintGifts(true))
        let jsonIpfsLink = ''
        try {
            jsonIpfsLink = await saveMetadata(title.value, description.value, properties.value, loadedImage, externalUrl, jwt)
        } catch (e) {
            console.log(e)
            showError(i18next.t('error.mintError'), i18next.t('error.savingMetadata'))
            return false
        }
        dispatch(setNftJsonUrl(jsonIpfsLink))

        let addresses = []
        let tokenURIs = []
        for (let i = 0; i < batch; i++) {
            addresses.push(walletAddress)
            tokenURIs.push(jsonIpfsLink)
        }
        let mintedTokenIds: number[] = []
        try {
            const mintMethod = batch > 1 ?
                contract.methods.mintWithURIBatch(addresses, tokenURIs)
                :
                contract.methods.mintWithURI(walletAddress, jsonIpfsLink)
            const encodedABI = mintMethod.encodeABI()
            console.log(`sending transaction to ${CHAINS[currentNetwork].nftMinterContract721}`)
            const tx = await signer.sendTransaction({
                from: walletAddress,
                to: CHAINS[currentNetwork].nftMinterContract721,
                data: encodedABI,
            })
            dispatch(setNftTxSigned(true))

            try {
                const receipt = await tx.wait()
                if (receipt && receipt.status === 1) {
                    console.log(receipt)
                    for (let log of receipt.logs) {
                        for (let item of CHAINS[currentNetwork].nftMinterContract721Abi) {
                            if (item.type !== 'event') {
                                continue
                            }
                            if (item.name !== 'Transfer') {
                                continue
                            }
                            const signature = item.name + '(' + item.inputs?.map((input) => input.type).join(',') + ')'
                            const hash = web3?.utils.sha3(signature)
                            if (hash === log.topics[0]) {
                                mintedTokenIds.push(Number(log.topics[3]))
                                break
                            }
                        }
                    }
                    dispatch(setNftTxId(receipt.transactionHash))
                }
            } catch (error: any) {
                console.log(error.receipt)
                showError(i18next.t('error.mintError'), i18next.t('error.mintErrorText'))
                return false
            }
        } catch (e: any) {
            const error = e.message || e.toString()
            console.log(e)
            showError(i18next.t('error.mintError'), error)
            return false
        }
        if (mintedTokenIds.length !== batch) {
            console.log(`Mint error. Minted ${mintedTokenIds.length} of ${batch}`)
            showError(i18next.t('error.mintError'), i18next.t('error.mintBatchError', {
                value: mintedTokenIds.length,
                value2: batch
            }))
            return false
        }

        dispatch(setMintedTokenId(mintedTokenIds))
        if (!approvedForAll) {
            try {
                if (mintedTokenIds.length === 1) {
                    if ((await contract.methods.getApproved(mintedTokenIds[0]).call()) !== approvingAddress) {
                        const method = contract.methods.approve(approvingAddress, mintedTokenIds[0])
                        const encodedABI = method.encodeABI()
                        const tx = await signer.sendTransaction({
                            from: walletAddress,
                            to: CHAINS[currentNetwork].nftMinterContract721,
                            data: encodedABI,
                        })
                        await tx.wait()
                    }
                } else {
                    const method = contract.methods.setApprovalForAll(approvingAddress, true)
                    const encodedABI = method.encodeABI()
                    const tx = await signer.sendTransaction({
                        from: walletAddress,
                        to: CHAINS[currentNetwork].nftMinterContract721,
                        data: encodedABI,
                    })
                    await tx.wait()
                }
            } catch (e) {
                console.log(e)
                showError(i18next.t('error.error'), i18next.t('error.approvingTicket'))
                return false
            }
        }
        dispatch(setApprovedNfts(true))

        let gifts: any[] = []
        for (let id of mintedTokenIds) {
            gifts.push([
                [[_AssetType.ERC721, CHAINS[currentNetwork].nftMinterContract721], id.toString(), 0],
                NULL_ADDRESS,
                [],
                [], //unlockTime.value && unlockTime.value > 0 ? ['0x00', Math.floor(Date.now() / 1000) + unlockTime.value * 86400] : [],
                [],
                _AssetType.ERC721,
                0,
                '0x0000'
            ])
        }
        let collateralsArray: any[] = []
        let payableAmount = ethers.BigNumber.from(0)
        let coins: { [contract: string]: ethers.BigNumber } = {}
        for (let item of collaterals.value) {
            const tokenContract = item.token === CUSTOM_DROPDOWN_ITEM.id ? item.customContract : item.token
            const amount = ethers.utils.parseUnits(item.price, item.decimals)
            if (tokenContract === NULL_ADDRESS) {
                payableAmount = payableAmount.add(amount)
            } else {
                coins[tokenContract] = !coins[tokenContract] ? amount : coins[tokenContract].add(amount)
            }
            collateralsArray.push([
                [tokenContract === NULL_ADDRESS ? _AssetType.native : _AssetType.ERC20, tokenContract],
                0,
                amount.toString()
            ])
        }
        payableAmount = payableAmount.mul(batch)
        let trxs: ITransaction[] = []
        try {
            for (let key in coins) {
                const amount = coins[key].mul(batch)
                const coinContract = new web3.eth.Contract(Erc20Abi as AbiItem[], key)
                const balance = ethers.utils.parseUnits(await coinContract.methods.balanceOf(walletAddress).call(), 'wei')
                if (balance.gte(amount)) {
                    const allowance = await coinContract.methods.allowance(walletAddress, approvingAddress).call()
                    if (amount.gt(allowance)) {
                        const method = coinContract.methods.approve(approvingAddress, amount)
                        const encodedABI = method.encodeABI()
                        trxs.push({
                            from: walletAddress,
                            to: key,
                            data: encodedABI,
                        })
                    }
                } else {
                    showError(i18next.t('error.error'), i18next.t('error.insufficientBalance'))
                    return false
                }
            }
        } catch (e) {
            console.log(e)
            showError(i18next.t('error.error'), i18next.t('error.wrongCustomCoinContractText'))
            return false
        }
        dispatch(setApproveCoinCount(trxs.length))
        for (let i = 0; i < trxs.length; i++) {
            try {
                const tx = await signer.sendTransaction(trxs[i])
                await tx.wait()
                dispatch(setApprovedCoinCount(i + 1))
            } catch (e) {
                console.log(e)
                showError(i18next.t('error.error'), i18next.t('error.approvingCoin'))
                return false
            }
        }

        try {
            let encodedABI: string
            let wrapper: string
            if (selectedGiftCollection === publicDropdown.id) {
                const method = wrapperContract.methods.wrapBatch(gifts, collateralsArray, recipients.value)
                encodedABI = method.encodeABI()
                wrapper = CHAINS[currentNetwork].wrapperBatchPublicContract
            } else {
                const method = wrapperContract.methods.wrapBatch(gifts, collateralsArray, recipients.value, selectedGiftCollection)
                encodedABI = method.encodeABI()
                wrapper = CHAINS[currentNetwork].wrapperBatchContract
            }
            const trans: TransactionRequest = {
                data: encodedABI,
                from: walletAddress,
                to: wrapper,
                value: payableAmount.gt(0) ? payableAmount : undefined,
//              gasLimit: ethers.utils.parseUnits('0.01', 'gwei'),
            }
            const tx = await signer.sendTransaction(trans)
            const receipt = await tx.wait()
            if (receipt && receipt.status === 1) {
                console.log(receipt)
                let abi: AbiItem[]
                if (selectedGiftCollection === publicDropdown.id) {
                    abi = CHAINS[currentNetwork].wnftPublic721Abi
                } else {
                    abi = CHAINS[currentNetwork].sbtImpl721ContractAbi
                }
                let mintedGiftIds: number[] = []
                for (let log of receipt.logs) {
                    for (let item of abi) {
                        if (item.type !== 'event') {
                            continue
                        }
                        if (item.name !== 'Transfer') {
                            continue
                        }
                        const signature = item.name + '(' + item.inputs?.map((input) => input.type).join(',') + ')'
                        const hash = web3?.utils.sha3(signature)
                        const id = Number(log.topics[3])
                        if (hash === log.topics[0] && mintedTokenIds.indexOf(id) < 0) {
                            mintedGiftIds.push(id)
                            break
                        }
                    }
                }
                dispatch(setGiftsTxId(receipt.transactionHash))
                dispatch(setMintedGiftsIds(mintedGiftIds))
            }
        } catch (e) {
            console.log(e)
            showError(i18next.t('error.error'), i18next.t('error.mintErrorText'))
            return false
        }
        GATracker.sendEventTracker('Gifts', 'GiftsMinted')
        dispatch(setMintedGiftsCount(batch))
        dispatch(resetImage())
        dispatch(resetInputState())
        return true
    }
)

export const requestGiftsCollection = createAsyncThunk(
    'gifts/requestGiftsCollection',
    async (
        {network, address}: { network: string, address: string },
        {getState}
    ): Promise<IOnChainEventData | undefined | null> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !walletAddress || !web3) {
            return null
        }

        if (isNaN(Number(network)) || network !== currentNetwork || !ethers.utils.isAddress(address)) {
            return null
        }

        try {
            const contract = new web3.eth.Contract(CHAINS[currentNetwork].eventsManagerContractAbi, CHAINS[currentNetwork].eventsManagerContract)
            const eventData = await contract.methods.getDataForEvent(address).call()
            const cntr = new web3.eth.Contract(CHAINS[currentNetwork].sbtImpl721ContractAbi, address)
            const name = await cntr.methods.name().call()
            const symbol = await cntr.methods.symbol().call()
            const owner = (await cntr.methods.owner().call()).toLowerCase()
            return {
                certificate: {finish: Number(eventData.certificate.finish), start: Number(eventData.certificate.start)},
                eventContract: address,
                eventName: name,
                eventTicker: symbol,
                owner,
                sbtRules: eventData.sbtRules,
                tickets: eventData.tickets.toLowerCase(),
                useTicket: {finish: Number(eventData.useTicket.finish), start: Number(eventData.useTicket.start)},
            }
        } catch (e) {
            console.log(e)
        }
        return undefined
    }
)

export const requestGiftsCollections = createAsyncThunk(
    'gifts/requestGiftsCollections',
    async (_, {getState}): Promise<ICollection[] | null> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !walletAddress || !web3) {
            return null
        }

        try {
            const contract = new web3.eth.Contract(CHAINS[currentNetwork].eventsManagerContractAbi, CHAINS[currentNetwork].eventsManagerContract)
            const result = await contract.methods.getUsersCollections(walletAddress).call()
            let events: ICollection[] = []
            for (let item of result) {
                if (Number(item.assetType) !== _AssetType.ERC721) {
                    continue
                }

                const collection = await contract.methods.getDataForEvent(item.contractAddress).call()
                if (collection && collection.tickets !== NULL_ADDRESS) {
                    continue
                }

                const cntr = new web3.eth.Contract(CHAINS[currentNetwork].sbtImpl721ContractAbi, item.contractAddress)
                const name = await cntr.methods.name().call()
                events.push({
                    assetType: Number(item.assetType),
                    contractAddress: item.contractAddress.toLowerCase(),
                    name,
                    //todo: set createdAt and showcaseType
                    createdAt: 0,
                    showcaseType: 'Classic',
                })
            }
            return events
        } catch (e) {
            console.log(e)
        }
        return []
    }
)

export const requestUserWnfts = createAsyncThunk(
    'gifts/requestUserWnfts',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress} = state.app
        const {jwt} = state.auth
        const {currentGiftsCollection} = state.gifts

        let response: SliceResponse = {}
        if (!currentNetwork || !jwt || !walletAddress || !currentGiftsCollection) {
            response.error = {text: i18next.t('error.networkWalletOrEventNotSelected')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.get(`${API_URL}oracle/wnft/721/user/${Number(currentNetwork)}/${walletAddress}/${currentGiftsCollection.eventContract}`, config)
                let list: IToken[] = []
                for (let item of result.data.tokens) {
                    list.push({
                        assetType: item.assetType,
                        network: currentNetwork,
                        contract: item.contract.toLowerCase(),
                        tokenId: item.tokenId,
                        tokenUri: createIpfsLink(item.tokenUri),
                        owner: item.owner.toLowerCase(),
                        blockNum: Number(item.blockNum),
                        rules: item.rules,
                    })
                }
                response.status = result.status
                response.data = list
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setUserWnfts(value))
        }
        dispatch(checkResponse(response))
    }
)

export const giftsSlice = createSlice({
    name: 'gifts',
    initialState,
    reducers: {
        resetMintGiftsState: (state) => {
            state.mintedGiftsCount = null
            state.approveCoinCount = null
            state.approvedCoinCount = 0
            state.approvedNfts = false
            state.giftsTxId = null
            state.mintedGiftsCount = null
            state.mintedGiftsIds = null
            state.needApproveNfts = false
        },
        resetState: (state) => {
            let key: keyof GiftsState
            for (key in initialState) {
                Reflect.set(state, key, initialState[key])
            }
        },
        setApproveCoinCount: (state, action: PayloadAction<number | null>) => {
            state.approveCoinCount = action.payload
        },
        setApprovedCoinCount: (state, action: PayloadAction<number>) => {
            state.approvedCoinCount = action.payload
        },
        setApprovedNfts: (state, action: PayloadAction<boolean>) => {
            state.approvedNfts = action.payload
        },
        setCurrentGiftsCollectoin: (state, action: PayloadAction<IOnChainEventData | undefined | null>) => {
            state.currentGiftsCollection = action.payload
        },
        setGeneratingImage: (state, action: PayloadAction<boolean>) => {
            state.generatingImage = action.payload
        },
        setGiftsCollections: (state, action: PayloadAction<ICollection[] | null>) => {
            state.giftsCollections = action.payload
        },
        setGiftsTxId: (state, action: PayloadAction<string | null>) => {
            state.giftsTxId = action.payload
        },
        setMintedGiftsCount: (state, action: PayloadAction<number | null>) => {
            state.mintedGiftsCount = action.payload
        },
        setMintedGiftsIds: (state, action: PayloadAction<number[] | null>) => {
            state.mintedGiftsIds = action.payload
        },
        setNeedApproveNfts: (state, action: PayloadAction<boolean>) => {
            state.needApproveNfts = action.payload
        },
        setUserWnfts: (state, action: PayloadAction<IToken[] | null>) => {
            state.userWnfts = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(generateImage.pending, (state) => {
            state.generatingImage = true
        })
        builder.addCase(generateImage.fulfilled, (state) => {
            state.generatingImage = false
        })
        builder.addCase(requestGiftsCollection.fulfilled, (state, action: PayloadAction<IOnChainEventData | undefined | null>) => {
            state.currentGiftsCollection = action.payload
        })
        builder.addCase(requestGiftsCollections.fulfilled, (state, action: PayloadAction<ICollection[] | null>) => {
            state.giftsCollections = action.payload
        })
    },
})

export const getApproveCoinCount = (state: RootState): number | null => state.gifts.approveCoinCount
export const getApprovedCoinCount = (state: RootState): number => state.gifts.approvedCoinCount
export const getApprovedNfts = (state: RootState): boolean => state.gifts.approvedNfts
export const getCurrentGiftsCollection = (state: RootState): IOnChainEventData | undefined | null => state.gifts.currentGiftsCollection
export const getGeneratingImage = (state: RootState): boolean => state.gifts.generatingImage
export const getGiftsCollections = (state: RootState): ICollection[] | null => state.gifts.giftsCollections
export const getGiftsTxId = (state: RootState): string | null => state.gifts.giftsTxId
export const getMintedGiftsCount = (state: RootState): number | null => state.gifts.mintedGiftsCount
export const getMintedGiftsIds = (state: RootState): number[] | null => state.gifts.mintedGiftsIds
export const getNeedApproveNfts = (state: RootState): boolean => state.gifts.needApproveNfts
export const getUserWnfts = (state: RootState): IToken[] | null => state.gifts.userWnfts

export const {
    resetMintGiftsState,
    resetState,
    setApproveCoinCount,
    setApprovedCoinCount,
    setApprovedNfts,
    setCurrentGiftsCollectoin,
    setGeneratingImage,
    setGiftsCollections,
    setGiftsTxId,
    setMintedGiftsCount,
    setMintedGiftsIds,
    setNeedApproveNfts,
    setUserWnfts,
} = giftsSlice.actions

export default giftsSlice.reducer
