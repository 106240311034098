import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {Link, useMatch, useMatches} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {WalletBlock} from '../wallet'
import {ToggleDarkMode} from '../elements'
import {ENVIRONMENT, MAIN_MENUS, SUB_MENU} from '../../utils/constants'
import {getCurrentEventId, getEvent} from '../../store/eventsSlice'
import {getWalletAddress} from '../../store/appSlice'
import {ClassicIcon, CloseIcon, MyShChIcon, OnChainIcon, SmartIcon} from '../icons'
import ChevronDown from '../icons/ChevronDown'

const Header = () => {
    const {t} = useTranslation()
    const urlMatch = useMatch('/event/:organizerUrl/:eventUrl')
    const matches = useMatches()
    const [opened, setOpened] = useState(false)
    const [openedSmart, setOpenedSmart] = useState(false)
    const [openedClassic, setOpenedClassic] = useState(false)
    const [openedOnChain, setOpenedOnChain] = useState(false)
    const currentEventId = useSelector(getCurrentEventId)
    const event = useSelector(getEvent(currentEventId))
    const walletAddress = useSelector(getWalletAddress)
    let activeMenu = -1
    for (let match of matches) {
        if ((match.handle as any)?.mainMenu !== undefined) {
            activeMenu = (match.handle as any).mainMenu
            break
        }
    }
    let activeSubMenu = -1
    for (let match of matches) {
        if ((match.handle as any)?.subMenu !== undefined) {
            activeSubMenu = (match.handle as any).subMenu
            break
        }
    }

    const closeMenu = () => {
        setOpened(false)
    }

    return <header className="s-header">
        <div className="container">
            <div className="d-flex align-items-center h-100">
                <Link to={'/'} className="s-header__logo">
                    <MyShChIcon/>
                    {ENVIRONMENT !== 'prod' ?
                        <span className="app-status">{ENVIRONMENT}</span>
                        :
                        <span className="app-status text-light">Beta</span>
                    }
                </Link>
                {!urlMatch || (event && event.organizer === walletAddress) ?
                    <>
                        <button
                            className={`s-header__nav-toggle ${opened ? 'active' : ''}`}
                            onClick={() => setOpened(!opened)}
                        >
                            <span className="burger-lines"></span>
                        </button>
                        <div className={`s-header__nav ${opened ? 'show' : ''}`}>
                            <div className="s-header__nav-content">
                                <div className="s-header__nav-header">
                                    <div className="logo">
                                        <MyShChIcon/>
                                    </div>
                                    <button className="btn p-2" onClick={closeMenu}>
                                        <CloseIcon/>
                                    </button>
                                </div>
                                <div className="s-header__nav-scroll">
                                    <div
                                        className={`nav-item nav-item_smart has-dropdown ${openedSmart ? 'show' : ''}`}>
                                        <div
                                            className={`nav-item__link has-arrow ${activeMenu === MAIN_MENUS.smart ? 'active' : ''}`}
                                            onClick={() => setOpenedSmart(!openedSmart)}
                                        >
                                            <SmartIcon className={'icon-path'}/>
                                            <span className="me-1">{t('menu.Smart')}</span>
                                            <ChevronDown className={'icon-arrow'}/>
                                        </div>
                                        <div className="nav-dropdown">
                                            <div className="nav-item">
                                                <Link
                                                    to="/smart/mint"
                                                    className={`nav-item__link ${activeSubMenu === SUB_MENU.smartMint ? 'active' : ''}`}
                                                    onClick={closeMenu}
                                                >
                                                    {t('menu.smartMint')}
                                                    <small className="d-block text-muted w-100 mt-xl-1">
                                                        {t('menu.smartMintDescription')}
                                                    </small>
                                                </Link>
                                            </div>
                                            <div className="nav-item">
                                                <Link
                                                    to="/smart/showcases"
                                                    className={`nav-item__link ${activeSubMenu === SUB_MENU.smartShowcases ? 'active' : ''}`}
                                                    onClick={closeMenu}
                                                >
                                                    {t('menu.smartShowcase')}
                                                    <small className="d-block text-muted w-100 mt-xl-1">
                                                        {t('menu.smartShowcaseDescription')}
                                                    </small>
                                                </Link>
                                            </div>
                                            <div className="nav-item">
                                                <Link
                                                    to="/smart/events"
                                                    className={`nav-item__link ${activeSubMenu === SUB_MENU.smartEvents ? 'active' : ''}`}
                                                    onClick={closeMenu}
                                                >
                                                    {t('menu.events')}
                                                    <small className="d-block text-muted w-100 mt-xl-1">
                                                        {t('menu.eventsDescription')}
                                                    </small>
                                                </Link>
                                            </div>
                                            <div className="nav-item">
                                                <Link
                                                    to="/smart/sbt"
                                                    className={`nav-item__link ${activeSubMenu === SUB_MENU.smartSbt ? 'active' : ''}`}
                                                    onClick={closeMenu}
                                                >
                                                    {t('menu.certificates')}
                                                    <small className="d-block text-muted w-100 mt-xl-1">
                                                        {t('menu.certificatesDescription')}
                                                    </small>
                                                </Link>
                                            </div>
                                            <div className="nav-item">
                                                <Link
                                                    to="/smart/stats/events"
                                                    className={`nav-item__link ${activeSubMenu === SUB_MENU.smartStat ? 'active' : ''}`}
                                                    onClick={closeMenu}
                                                >
                                                    {t('menu.stats')}
                                                    <small className="d-block text-muted w-100 mt-xl-1">
                                                        {t('menu.statsDescription')}
                                                    </small>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`nav-item nav-item_classic has-dropdown ${openedClassic ? 'show' : ''}`}>
                                        <div
                                            className={`nav-item__link has-arrow ${activeMenu === MAIN_MENUS.classic ? 'active' : ''}`}
                                            onClick={() => setOpenedClassic(!openedClassic)}
                                        >
                                            <ClassicIcon className={'icon-path'}/>
                                            <span className="me-1">{t('menu.Classic')}</span>
                                            <ChevronDown className={'icon-arrow'}/>
                                        </div>
                                        <div className="nav-dropdown">
                                            <div className="nav-item">
                                                <Link
                                                    to="/classic/mint"
                                                    className={`nav-item__link ${activeSubMenu === SUB_MENU.classicMint ? 'active' : ''}`}
                                                    onClick={closeMenu}
                                                >
                                                    {t('menu.mint')}
                                                    <small className="d-block text-muted w-100 mt-xl-1">
                                                        {t('menu.mintDescription')}
                                                    </small>
                                                </Link>
                                            </div>
                                            <div className="nav-item">
                                                <Link
                                                    to="/classic/showcases"
                                                    className={`nav-item__link ${activeSubMenu === SUB_MENU.classicShowcases ? 'active' : ''}`}
                                                    onClick={closeMenu}
                                                >
                                                    {t('menu.showcase')}
                                                    <small className="d-block text-muted w-100 mt-xl-1">
                                                        {t('menu.showcaseDescription')}
                                                    </small>
                                                </Link>
                                            </div>
                                            <div className="nav-item">
                                                <Link
                                                    to="/classic/events"
                                                    className={`nav-item__link ${activeSubMenu === SUB_MENU.classicEvents ? 'active' : ''}`}
                                                    onClick={closeMenu}
                                                >
                                                    {t('menu.events')}
                                                    <small className="d-block text-muted w-100 mt-xl-1">
                                                        {t('menu.eventsDescription')}
                                                    </small>
                                                </Link>
                                            </div>
                                            <div className="nav-item">
                                                <Link
                                                    to="/classic/sbt"
                                                    className={`nav-item__link ${activeSubMenu === SUB_MENU.classicSbt ? 'active' : ''}`}
                                                    onClick={closeMenu}
                                                >
                                                    {t('menu.certificates')}
                                                    <small className="d-block text-muted w-100 mt-xl-1">
                                                        {t('menu.certificatesDescription')}
                                                    </small>
                                                </Link>
                                            </div>
                                            <div className="nav-item">
                                                <Link
                                                    to="/classic/stats/events"
                                                    className={`nav-item__link ${activeSubMenu === SUB_MENU.classicStat ? 'active' : ''}`}
                                                    onClick={closeMenu}
                                                >
                                                    {t('menu.stats')}
                                                    <small className="d-block text-muted w-100 mt-xl-1">
                                                        {t('menu.statsDescription')}
                                                    </small>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {ENVIRONMENT !== 'prod' ?
                                        <div
                                            className={`nav-item nav-item_onchain has-dropdown ${openedOnChain ? 'show' : ''}`}>
                                            <div
                                                className={`nav-item__link has-arrow ${activeMenu === MAIN_MENUS.onchain ? 'active' : ''}`}
                                                onClick={() => setOpenedOnChain(!openedOnChain)}
                                            >
                                                <OnChainIcon className={'icon-path'}/>
                                                <span className="me-1">{t('menu.Onchain')}</span>
                                                <ChevronDown className={'icon-arrow'}/>
                                            </div>
                                            <div className="nav-dropdown">
                                                <div className="nav-item">
                                                    <Link
                                                        to="/onchain/mint"
                                                        className={`nav-item__link ${activeSubMenu === SUB_MENU.onchainMint ? 'active' : ''}`}
                                                        onClick={closeMenu}
                                                    >
                                                        {t('menu.onchainMint')}
                                                        <small className="d-block text-muted w-100 mt-xl-1">
                                                            {t('menu.onchainMintDescription')}
                                                        </small>
                                                    </Link>
                                                </div>
                                                <div className="nav-item">
                                                    <Link
                                                        to="/onchain/showcases"
                                                        className={`nav-item__link ${activeSubMenu === SUB_MENU.onchainShowcases ? 'active' : ''}`}
                                                        onClick={closeMenu}
                                                    >
                                                        {t('menu.showcase')}
                                                        <small className="d-block text-muted w-100 mt-xl-1">
                                                            {t('menu.showcaseDescription')}
                                                        </small>
                                                    </Link>
                                                </div>
                                                <div className="nav-item">
                                                    <Link
                                                        to="/onchain/events"
                                                        className={`nav-item__link ${activeSubMenu === SUB_MENU.onchainEvents ? 'active' : ''}`}
                                                        onClick={closeMenu}
                                                    >{t('menu.management')}</Link>
                                                </div>
                                                <div className="nav-item">
                                                    <Link
                                                        to="/onchain/stats"
                                                        className={`nav-item__link ${activeSubMenu === SUB_MENU.onchainStat ? 'active' : ''}`}
                                                        onClick={closeMenu}
                                                    >
                                                        {t('menu.stats')}
                                                        <small className="w-100 mt-1">
                                                            {t('comingSoon')}
                                                        </small>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    <div className="nav-item">
                                        <Link
                                            to="/gifts"
                                            className={`nav-item__link ${activeMenu === MAIN_MENUS.gifts ? 'active' : ''}`}
                                            onClick={closeMenu}
                                        >
                                            {t('menu.gifts')}
                                        </Link>
                                    </div>
                                    <div className="nav-item">
                                        <Link
                                            to="/calendar"
                                            className={`nav-item__link ${activeMenu === MAIN_MENUS.calendar ? 'active' : ''}`}
                                            onClick={closeMenu}
                                        >
                                            {t('menu.calendar')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="s-header__bg"></div>
                    </>
                    :
                    null
                }
            </div>
            <div className="d-flex align-items-center">
                <ToggleDarkMode/>
                <WalletBlock/>
            </div>
        </div>
    </header>
}

export default Header
