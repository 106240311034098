import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {CHAINS} from '../../utils/constants'
import {NotFound} from '../static'
import {getSbtCollection, requestSbtCollection, setSbtCollection} from '../../store/sbtSlice'
import {SbtRow} from './index'
import {LoadingIcon} from '../icons'

const ShowSbtCollection = () => {
    const {t} = useTranslation()
    let {network, contractAddress} = useParams()
    network = `0x${Number(network).toString(16)}`
    if (!CHAINS[network]) {
        network = undefined
    }
    const [loadedCollection, setLoadedCollection] = useState(false)
    const collection = useSelector(getSbtCollection)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (contractAddress && network) {
            dispatch(requestSbtCollection({network, address: contractAddress}))
        }
        return () => {
            dispatch(setSbtCollection(null))
        }
    }, [network, contractAddress])
    useEffect(() => {
        if (loadedCollection && !collection && contractAddress) {
            setLoadedCollection(false)
            dispatch(requestSbtCollection({network: network || '', address: contractAddress}))
        } else if (!loadedCollection && collection) {
            setLoadedCollection(true)
        }
    }, [collection])

    const tokenList = useMemo(() => {
        if (!collection) {
            return null
        }

        return collection.tokens.length > 0 ? collection.tokens.map((item, index) => (
            <SbtRow item={item} key={index}/>
        )) : <div className="alert alert-primary text-center">{t('status.emptyCollection')}</div>
    }, [collection])

    if (!contractAddress || !network) {
        return <NotFound/>
    }

    return <div className="page-content">
        <div className="container">
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/sbt'}>{t('section.sbt')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('section.collection')}</li>
                </ol>
            </nav>
        </div>
        <div className="container">
            <h1 className="mb-3">{collection?.title || contractAddress}</h1>
{/*
            <div className="mb-4 pb-2 mb-lg-2 pb-lg-0">
                <div className="row align-items-center">
                    <div className="col-auto"><span className="text-muted me-2">Contract:</span><span>0x5...4e6</span>
                        <button className="btn btn-link p-1">
                            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M4.8 3.4c-.77 0-1.4.63-1.4 1.4V12c0 .77.63 1.4 1.4 1.4H12c.77 0 1.4-.63 1.4-1.4V4.8c0-.77-.63-1.4-1.4-1.4H4.8ZM1.4 4.8a3.4 3.4 0 0 1 3.4-3.4H12a3.4 3.4 0 0 1 3.4 3.4V12a3.4 3.4 0 0 1-3.4 3.4H4.8A3.4 3.4 0 0 1 1.4 12V4.8ZM17 9.6a1 1 0 0 1 1-1h2.4c1.22 0 2.2.98 2.2 2.2v8.8a3 3 0 0 1-3 3h-8.8a2.2 2.2 0 0 1-2.2-2.2V18a1 1 0 1 1 2 0v2.4c0 .11.09.2.2.2h8.8a1 1 0 0 0 1-1v-8.8a.2.2 0 0 0-.2-.2H18a1 1 0 0 1-1-1Z"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="col-auto"><span className="text-muted me-2">Ticker:</span><span>FFetYY </span></div>
                </div>
            </div>
            <div class="row gx-3 gx-xl-4 mb-4">
              <div class="col-12 col-md-4 col-lg-auto ms-lg-auto">
                <div class="btn-group w-100">
                  <div class="btn-group flex-grow-1 dropdown">
                    <button class="btn btn-sm btn-outline-primary btn-filter-select dropdown-toggle"> <span class="me-2">Any Date </span>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7 15.1a1 1 0 0 1-1.4 0l-4.8-4.8a1 1 0 1 1 1.4-1.4l4.1 4.09 4.1-4.1a1 1 0 1 1 1.4 1.42l-4.8 4.8Z"></path>
                      </svg>
                    </button>
                    <div class="dropdown-menu">Here comes the calendar</div>
                  </div>
                  <button class="btn btn-sm btn-outline-primary flex-grow-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3 4.1a1 1 0 0 1 1.4 0l3.6 3.6a1 1 0 0 1-1.4 1.4L12 6.22l-2.9 2.9a1 1 0 1 1-1.4-1.42l3.6-3.6ZM7.7 14.9a1 1 0 0 1 1.4 0l2.9 2.89 2.9-2.9a1 1 0 0 1 1.4 1.42l-3.6 3.6a1 1 0 0 1-1.4 0l-3.6-3.6a1 1 0 0 1 0-1.42Z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
*/}
            {collection ?
                <div className="row-table">
                    {tokenList}
                </div>
                :
                <LoadingIcon/>
            }
        </div>
    </div>
}

export default ShowSbtCollection
