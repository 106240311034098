import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
    getCollections,
    postMintCollectionAddress,
    requestMintCollectionsFromChain, setCollections,
} from '../../store/mintSlice'
import {AppDispatch} from '../../store/store'
import {checkWallet, getCurrentNetwork, getWalletAddress, sendRequestWithAuth} from '../../store/appSlice'
import {ButtonElement, DropdownPlaceholder, DropdownSelector} from '../elements'
import {IDropdownItem, TShowcaseType} from '../../store/types'

const AddMintCollection = () => {
    const {t} = useTranslation()
    const [collection, setCollection] = useState('')
    const [showcaseType, setShowcaseType] = useState<TShowcaseType>('Classic')
    const collections = useSelector(getCollections)
    const currentNetwork = useSelector(getCurrentNetwork)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        return () => {
            dispatch(setCollections(null))
        }
    }, [])
    useEffect(() => {
        dispatch(checkWallet({request: requestMintCollectionsFromChain(), network: currentNetwork || undefined}))
    }, [currentNetwork, walletAddress])

    const collectionsList: IDropdownItem[] | undefined = collections?.map((item): IDropdownItem => {
        return {id: item.contractAddress, name: item.name}
    })
    const showcaseTypeList: IDropdownItem[] = [
        {id: 'Classic', name: 'Classic'},
        {id: 'Smart', name: 'Smart'},
        {id: 'Onchain', name: 'Onchain'},
    ]

    const submitHandler = () => {
        dispatch(sendRequestWithAuth(postMintCollectionAddress({contractAddress: collection})))
    }

    return <div className="page-content">
        <div className="container">
            <div className="row mb-3">
                <div className="col">
                    <h1>{t('header.mintTickets')}</h1>
                </div>
            </div>
            {collectionsList ?
                <DropdownSelector list={collectionsList} setItem={setCollection} currentItem={collection}/>
                :
                <DropdownPlaceholder title={t('status.loading')}/>
            }
            <DropdownSelector list={showcaseTypeList} setItem={setShowcaseType} currentItem={showcaseType}/>
            <ButtonElement onClick={submitHandler}>{t('button.add')}</ButtonElement>
        </div>
    </div>
}

export default AddMintCollection
