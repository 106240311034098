import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {ethers} from 'ethers'
import {
    getCurrentNetwork,
    getModalEditShowcase,
    getWalletAddress,
    setModalEditShowcase
} from '../../store/appSlice'
import {ButtonElement, DateElement, InputElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {CloseIcon} from '../icons'
import {getShowcase, setShowcaseParams} from '../../store/showcaseV2Slice'
import {CHAINS, SHOWCASE_TYPES} from '../../utils/constants'
import {TShowcaseType} from '../../store/types'
import {AdvancedSettingsBlock} from '../blocks'
import {getName} from '../../store/ensSlice'

interface propsType {
    show: boolean
}

const ModalEditShowcase = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const showcase = useSelector(getShowcase)
    const walletAddress = useSelector(getWalletAddress)
    const [beneficiary, setBeneficiary] = useState(showcase ? showcase.beneficiary : '')
    const [beneficiaryError, setBeneficiaryError] = useState('')
    const [disableDate, setDisableDate] = useState<Date | null>(showcase ? new Date(showcase.disableAfter * 1000) : null)
    const [disableError, setDisableError] = useState('')
    const [enableDate, setEnableDate] = useState<Date | null>(showcase ? new Date(showcase.enableAfter * 1000) : null)
    const [enableError, setEnableError] = useState('')
    const beneficiaryName = useSelector(getName(beneficiary))
    const currentNetwork = useSelector(getCurrentNetwork)
    const modal = useSelector(getModalEditShowcase)
    let type: TShowcaseType | null = null
    if (showcase && currentNetwork) {
        for (let key in SHOWCASE_TYPES) {
            if (CHAINS[currentNetwork].showcaseV2PriceModel[key as TShowcaseType] &&
                CHAINS[currentNetwork].showcaseV2PriceModel[key as TShowcaseType] === showcase.priceModel
            ) {
                type = key as TShowcaseType
                break
            }
        }
    }

    const dispatch = useDispatch<AppDispatch>()

    const closeModal = () => {
        dispatch(setModalEditShowcase(null))
    }
    const editDisplay = () => {
        if (!modal || !type || !showcase) {
            return
        }

        let error = false
        const enableTimestamp = enableDate ? Math.floor(enableDate.getTime() / 1000) : 0
        const disableTimestamp = disableDate ? Math.floor(disableDate.getTime() / 1000) : 0

        if (enableTimestamp <= 0) {
            error = true
            setEnableError(t('error.wrong', {name: t('form.label.enableDate')}))
        } else {
            setEnableError('')
        }
        if (disableTimestamp <= 0) {
            error = true
            setDisableError(t('error.wrong', {name: t('form.label.disableDate')}))
        } else if (disableTimestamp <= enableTimestamp) {
            error = true
            setDisableError( t('error.mustBeGreater', {name: t('form.label.disableDate'), value: t('form.label.enableDate')}))
        } else {
            setDisableError('')
        }

        let receiver: string = ethers.utils.isAddress(beneficiary) ? beneficiary : (beneficiaryName || '')
        if (error || !walletAddress || receiver === '') {
            return
        }

        if (showcase.enableAfter === enableTimestamp && showcase.disableAfter === disableTimestamp) {
            closeModal()
        } else {
            dispatch(setShowcaseParams({
                showcaseName: modal,
                beneficiary: receiver,
                enableAfter: enableTimestamp,
                disableAfter: disableTimestamp,
                edit: true,
                type,
            }))
        }
    }

    if (!modal || modal === '') {
        closeModal()
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Edit a showcase"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">{t('modal.title.editShowcase')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-4">
                                <InputElement
                                    value={modal || ''}
                                    onChange={() => {
                                    }}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={enableDate}
                                    onChange={setEnableDate}
                                    label={t('form.label.enableDate')}
                                    errorText={enableError}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={disableDate}
                                    onChange={setDisableDate}
                                    label={t('form.label.disableDate')}
                                    errorText={disableError}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <AdvancedSettingsBlock
                                title={t('button.advancedSettings')}>
                                <InputElement
                                    alert={t('alert.showcaseBeneficiary')}
                                    label={t('form.label.beneficiary')}
                                    onChange={setBeneficiary}
                                    value={beneficiary}
                                    isAddress={true}
                                    errorText={beneficiaryError}
                                    setError={setBeneficiaryError}
                                />
                            </AdvancedSettingsBlock>
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-end">
                    <ButtonElement
                        className={'flex-grow-1 flex-lg-grow-0'}
                        onClick={editDisplay}
                    >{t('button.save')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalEditShowcase
