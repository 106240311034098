import React, {ReactNode, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {ethers} from 'ethers'
import {useConnectWallet} from '@web3-onboard/react'
import i18next from 'i18next'
import {AlertElement, ButtonElement, DropdownSelector, InputElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {CHAINS, CUSTOM_DROPDOWN_ITEM, ERC20_TOKENS, MAX_COLLATERAL_LEN, NULL_ADDRESS} from '../../utils/constants'
import {getCoins, getCoinsObject, getStandardCoinsList, requestCoin, requestCoins} from '../../store/coinsSlice'
import {getCurrentNetwork, setModalError} from '../../store/appSlice'
import {checkPriceFormat} from '../../utils/functions'
import {CollateralRow} from './index'
import {
    addCollateral,
    delCollateral,
    editCollateral,
    getCollaterals,
    getRecipients,
    setCollaterals
} from '../../store/inputSlice'
import {getBalances, requestBalance} from '../../store/userSlice'
import Loading from '../icons/Loading'
import {ConnectWalletButton} from '../wallet'
import {getMintedGiftsCount} from '../../store/giftsSlice'

const CollateralBlock = () => {
    const {t} = useTranslation()
    const [collateralAmount, setCollateralAmount] = useState('')
    const [collateralAmountError, setCollateralAmountError] = useState('')
    const [customContract, setCustomContract] = useState('')
    const [customContractError, setCustomContractError] = useState('')
    const balances = useSelector(getBalances)
    const coins = useSelector(getCoins)
    const coinsList = useSelector(getStandardCoinsList)
    const collaterals = useSelector(getCollaterals)
    const currentNetwork = useSelector(getCurrentNetwork)
    const [selectedCoin, setSelectedCoin] = useState(coinsList[0]?.id)
    const [selectedCoinChanged, setSelectedCoinChanged] = useState(false)
    const coinsObject = useSelector(getCoinsObject)
    const mintedGiftsCount = useSelector(getMintedGiftsCount)
    const recipients = useSelector(getRecipients)
    const selectedCoinContract = selectedCoin === CUSTOM_DROPDOWN_ITEM.id ? customContract : selectedCoin

    const dispatch = useDispatch<AppDispatch>()
    const [{wallet}] = useConnectWallet()

    useEffect(() => {
        return () => {
            dispatch(setCollaterals([]))
        }
    }, [])
    useEffect(() => {
        dispatch(requestCoins())
    }, [currentNetwork])
    useEffect(() => {
        if (coinsList.length > 0 && selectedCoin !== coinsList[0].id && !selectedCoinChanged) {
            setSelectedCoin(coinsList[0].id)
        }
    }, [coinsList])
    useEffect(() => {
        reqBalance()
    }, [customContract, selectedCoin])
    useEffect(() => {
        if (mintedGiftsCount !== null) {
            reqBalance()
        }
    }, [mintedGiftsCount])

    let balance: string | undefined = undefined
    if (currentNetwork) {
        for (let item of coins || []) {
            if (ERC20_TOKENS[currentNetwork].indexOf(item.contract) >= 0) {
                for (let i = 0; i < collaterals.value.length; i++) {
                    const price = collaterals.value[i]
                    if (((price.token !== CUSTOM_DROPDOWN_ITEM.id && item.contract === price.token) ||
                            (price.token === CUSTOM_DROPDOWN_ITEM.id && item.contract === price.customContract)) &&
                        price.decimals !== item.decimals
                    ) {
                        dispatch(editCollateral({index: i, value: {...price, decimals: item.decimals}}))
                    }
                }
            }
        }
        switch (selectedCoin) {
            case NULL_ADDRESS:
                balance = wallet?.accounts[0]?.balance?.[CHAINS[currentNetwork].token]
                break
            case CUSTOM_DROPDOWN_ITEM.id:
                balance = balances[customContract]?.balance
                break
            default:
                balance = balances[selectedCoin]?.balance
        }
    }

    const addCollateralHandler = () => {
        if (!currentNetwork) {
            return
        }

        if (collaterals.value.length === MAX_COLLATERAL_LEN) {
            dispatch(setModalError({
                text: t('error.mustBeLessOrEqual', {
                    name: t('header.collateral'),
                    value: MAX_COLLATERAL_LEN
                })
            }))
            return
        }

        if (!ethers.utils.isAddress(selectedCoinContract)) {
            setCustomContractError(t('error.customContractError', {name: CHAINS[currentNetwork].eipPrefix}))
            return
        }

        if (!coinsObject[selectedCoinContract]) {
            setCustomContractError(t('error.coinNotLoaded'))
            return
        }

        setCustomContractError('')
        const decimals = coinsObject[selectedCoinContract].decimals
        let amount = collateralAmount
        if (amount === '' || !checkPriceFormat(amount, decimals)) {
            setCollateralAmountError(t('error.wrongFormat'))
            return
        }

        if (amount.indexOf('.') > 0 && amount.indexOf('.') === amount.length - 1) {
            amount = amount.substring(0, amount.length - 1)
        }

        dispatch(addCollateral({
            price: amount,
            decimals,
            token: selectedCoin,
            customContract,
            customContractError: '',
            priceError: ''
        }))
        setCollateralAmountError('')
        setCollateralAmount('')
        setCustomContract('')
    }
    const checkButtonAddDisabled = (): boolean => {
        if (balance === undefined || collateralAmount === '') {
            return true
        }

        if (ethers.utils.parseEther(balance).lt(ethers.utils.parseEther(collateralAmount).mul(recipients.value.length || 1))) {
            if (collateralAmountError !== t('error.insufficientBalance')) {
                setCollateralAmountError(t('error.insufficientBalance'))
            }
            return true
        }

        if (collateralAmountError !== '') {
            setCollateralAmountError('')
        }
        return false
    }
    const delCollateralHandler = (index: number) => {
        dispatch(delCollateral(index))
    }
    const editCollateralAmountHandler = (value: string) => {
        let amount = value.replace(',', '.')
        const decimals = coinsObject[selectedCoinContract]?.decimals || 18
        if (amount === '' || checkPriceFormat(amount, decimals)) {
            const pos = amount.indexOf('.')
            if (pos > 0) {
                amount = amount.substring(0, pos + 1 + decimals)
            }
            setCollateralAmount(amount)
            setCollateralAmountError('')
        } else {
            setCollateralAmountError(t('error.wrongFormat'))
        }
    }
    const editCollateralHandler = (index: number, value: string) => {
        let amount = value.replace(',', '.')
        const decimals = coinsObject[selectedCoinContract]?.decimals || 18
        if (amount === '' || checkPriceFormat(amount, decimals)) {
            const pos = amount.indexOf('.')
            if (pos > 0) {
                amount = amount.substring(0, pos + 1 + decimals)
            }
            dispatch(editCollateral({index, value: {...collaterals.value[index], price: amount}}))
        }
    }
    const editCustomContractHandler = (value: string) => {
        if (!currentNetwork) {
            return
        }

        if (value === '' || !ethers.utils.isAddress(value)) {
            setCustomContract('')
            setCustomContractError(t('error.customContractError', {name: CHAINS[currentNetwork].eipPrefix}))
            return
        }

        setCustomContract(value.toLowerCase())
        setCustomContractError('')
        dispatch(requestCoin({address: value, network: currentNetwork}))
    }
    const reqBalance = () => {
        if (selectedCoin !== NULL_ADDRESS) {
            if (selectedCoin !== CUSTOM_DROPDOWN_ITEM.id) {
                dispatch(requestBalance(selectedCoin))
            } else if (ethers.utils.isAddress(customContract)) {
                dispatch(requestBalance(customContract))
            }
        }
    }
    const selectCoinHandler = (id: any) => {
        if (!selectedCoinChanged) {
            setSelectedCoinChanged(true)
        }
        setSelectedCoin(id)
        setCustomContractError('')
    }

    const collateralList = useMemo(() => {
        if (!currentNetwork) {
            return null
        }

        if (collaterals.value.length === 0) {
            return <AlertElement>{t('status.emptyList')}</AlertElement>
        }

        let list: ReactNode[] = []
        for (let i = collaterals.value.length - 1; i >= 0; i--) {
            const item = collaterals.value[i]
            list.push(<CollateralRow
                key={i}
                address={item.token === CUSTOM_DROPDOWN_ITEM.id ? item.customContract : item.token}
                amount={item.price}
                delHandler={delCollateralHandler}
                editHandler={editCollateralHandler}
                eipPrefix={CHAINS[currentNetwork].eipPrefix}
                icon={CHAINS[currentNetwork].icon}
                index={i}
                label={CHAINS[currentNetwork].label}
            />)
        }
        return list
    }, [collaterals.value, i18next.language])

    return <div className="mb-6 pb-4">
        <div className="upload-container for-tables">
            <div className="upload-poopover">
                <div className="inner">
                    <div className="h3 mb-0">{t('action.dropFile')}</div>
                </div>
            </div>
            <h2 className="mb-4">{t('header.collateral')}</h2>
            {currentNetwork ?
                <>
                    <div className="row gx-2 pe-lg-2">
                        <div className="col-lg-6 col-xl-5">
                            <div className="mb-3 mb-lg-0">
                                <div className="input-group">
                                    <DropdownSelector
                                        currentItem={selectedCoin}
                                        list={coinsList}
                                        setItem={selectCoinHandler}
                                    />
                                    <InputElement
                                        errorText={customContractError}
                                        value={selectedCoinContract}
                                        disabled={selectedCoin !== CUSTOM_DROPDOWN_ITEM.id}
                                        onChange={editCustomContractHandler}
                                    />
                                </div>
                            </div>
{/*
                            <div className="d-none d-lg-block mt-2">
                                <button className="btn btn-link px-0 py-1">{t('button.uploadCsv')}</button>
                                <span> {t('action.orDragAndDrop')}</span>
                            </div>
*/}
                        </div>
                        <div className="col-lg-2">
                            <div className="mb-2 mb-lg-0">
                                <InputElement
                                    errorText={collateralAmountError}
                                    value={collateralAmount}
                                    onChange={editCollateralAmountHandler}
                                    placeholder={t('word.amount')}
                                />
                                <div className="mt-1">
                                    <ButtonElement
                                        link
                                        className={'px-0 py-1 py-lg-2'}
                                        onClick={() => {
                                            setCollateralAmount(balance || '')
                                        }}
                                    >
                                        <small>
                                            {t('word.max')}:
                                            {' '}
                                            {selectedCoin === CUSTOM_DROPDOWN_ITEM.id && customContract === '' ?
                                                '...'
                                                :
                                                balance || <Loading/>
                                            }
                                        </small>
                                    </ButtonElement>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="mb-3 mb-lg-0">
                                <ButtonElement
                                    outline
                                    className={'w-100'}
                                    onClick={addCollateralHandler}
                                    disabled={checkButtonAddDisabled()}
                                >{t('button.add')}</ButtonElement>
                            </div>
                        </div>
                    </div>
                    <div className="d-lg-none text-center">
                        <button className="btn btn-link px-0 py-1">{t('button.uploadCsv')}</button>
                        <span className="d-none d-lg-inline"> {t('action.orDragAndDrop')}</span>
                    </div>
                    <div className="mt-3">
                        <div className="mb-2">
                            {collaterals.value.length} {t('word.outOf')}{' '}
                            <span className="text-muted">{MAX_COLLATERAL_LEN}</span>
                        </div>
                        {collaterals.error.status ?
                            <div className="mb-2">
                                <span className="text-danger">{collaterals.error.text}</span>
                            </div>
                            :
                            null
                        }
                        <div className="row">
                            <div className="col-lg-10 col-xl-9">
                                <div className="row-table row-table_with-scroll">
                                    {collateralList}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <ConnectWalletButton/>
            }
        </div>
    </div>
}

export default CollateralBlock
