import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getUser, signUserNonce} from '../../store/authSlice'
import {AppDispatch} from '../../store/store'
import {SignPencilIcon} from '../icons'
import {ButtonElement} from '../elements'

const HeaderAuthAlert = () => {
    const {t} = useTranslation()
    const user = useSelector(getUser)
    const dispatch = useDispatch<AppDispatch>()

    const signMessage = () => {
        dispatch(signUserNonce())
    }

    if (!user || user.auth) {
        return null
    }

    return <div className="alert alert-warning mb-0 rounded-0 py-2 z-1">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12 col-md">
                    <div className="d-flex">
                        <SignPencilIcon className={'flex-shrink-0 me-3'}/>
                        <span className="mt-1">{t('alert.headerSignAuthMessage')}</span>
                    </div>
                </div>
                <div className="col-12 col-md-auto mt-3 mt-md-0">
                    <ButtonElement
                        small
                        className={'btn-warning w-100'}
                        onClick={signMessage}
                    >{t('button.signAuthMessage')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>

}

export default HeaderAuthAlert
