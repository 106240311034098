import React, {useContext, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {ThemeContext} from '../../context'
import {getShowcaseType, setShowcaseType} from '../../store/appSlice'

const LayoutOnChain = () => {
    const {changeDesignMode} = useContext(ThemeContext)
    const showcaseType = useSelector(getShowcaseType)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setShowcaseType('Onchain'))
        changeDesignMode('onchain-mode')
        return () => {
            dispatch(setShowcaseType(null))
            changeDesignMode(null)
        }
    }, [])

    return showcaseType === 'Onchain' ? <Outlet/> : null
}

export default LayoutOnChain
