import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getModalEditDisplay, getWalletAddress, setModalEditDisplay} from '../../store/appSlice'
import {ButtonElement, DateElement, InputElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {getShowcase, setDisplayParams} from '../../store/launchpadSlice'
import {CloseIcon} from '../icons'

interface propsType {
    show: boolean
}

const ModalEditDisplay = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const showcase = useSelector(getShowcase)
    const [disableDate, setDisableDate] = useState<Date | null>(showcase ? new Date(showcase.disableAfter * 1000) : null)
    const [disableError, setDisableError] = useState('')
    const [enableDate, setEnableDate] = useState<Date | null>(showcase ? new Date(showcase.enableAfter * 1000) : null)
    const [enableError, setEnableError] = useState('')
    const modal = useSelector(getModalEditDisplay)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    const closeModal = () => {
        dispatch(setModalEditDisplay(null))
    }
    const editDisplay = () => {
        if (!modal) {
            return
        }

        let error = false
        const enableTimestamp = enableDate ? Math.floor(enableDate.getTime() / 1000) : 0
        const disableTimestamp = disableDate ? Math.floor(disableDate.getTime() / 1000) : 0

        if (enableTimestamp <= 0) {
            error = true
            setEnableError(t('error.wrong', {name: t('form.label.enableDate')}))
        } else {
            setEnableError('')
        }
        if (disableTimestamp <= 0) {
            error = true
            setDisableError(t('error.wrong', {name: t('form.label.disableDate')}))
        } else if (disableTimestamp <= enableTimestamp) {
            error = true
            setDisableError(t('error.mustBeGreater', {name: t('form.label.disableDate'), value: t('form.label.enableDate')}))
        } else {
            setDisableError('')
        }

        if (error || !walletAddress) {
            return
        }
        dispatch(setDisplayParams({
            displayName: modal,
            beneficiary: walletAddress,
            enableAfter: enableTimestamp,
            disableAfter: disableTimestamp,
            edit: true,
        }))
    }

    if (!modal || modal === '') {
        closeModal()
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Edit a showcase"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">{t('modal.title.editShowcase')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-4">
                                <InputElement
                                    value={modal || ''}
                                    onChange={() => {}}
                                    readonly={true}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={enableDate}
                                    onChange={setEnableDate}
                                    label={t('form.label.enableDate')}
                                    errorText={enableError}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={disableDate}
                                    onChange={setDisableDate}
                                    label={t('form.label.disableDate')}
                                    errorText={disableError}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-end">
                    <ButtonElement
                        className={'flex-grow-1 flex-lg-grow-0'}
                        onClick={editDisplay}
                    >{t('form.label.save')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalEditDisplay
