import React, {useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getModalAddEventToShowcase, getShowcaseType, setModalAddEventToShowcase} from '../../store/appSlice'
import {ButtonElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {CloseIcon} from '../icons'
import {SelectEvent, SelectOnchainEvent, SelectOrganizer, SelectTicketLevel} from '../profile'
import {addEventToShowcase, getSelectedEventId} from '../../store/eventsSlice'
import {getSelectedTicketLevelName} from '../../store/ticketsSlice'
import {addOnchainEventToShowcase, getSelectedEvent} from '../../store/onChainEventsSlice'

interface propsType {
    show: boolean
}

const ModalAddEventToShowcase = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const eventId = useSelector(getSelectedEventId)
    const modal = useSelector(getModalAddEventToShowcase)
    const selectedEvent = useSelector(getSelectedEvent)
    const showcaseType = useSelector(getShowcaseType)
    const ticketLevelName = useSelector(getSelectedTicketLevelName)

    const dispatch = useDispatch<AppDispatch>()

    const closeModal = () => {
        dispatch(setModalAddEventToShowcase(null))
    }
    const editHandler = () => {
        if (!modal) {
            return
        }

        if (showcaseType !== 'Onchain') {
            if (!eventId || ticketLevelName === '') {
                return
            }

            dispatch(addEventToShowcase(ticketLevelName))
        } else {
            if (!selectedEvent) {
                return
            }

            dispatch(addOnchainEventToShowcase())
        }
    }

    if (!modal) {
        closeModal()
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Edit a showcase"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">{t('modal.title.editShowcaseEvent')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <div className="row">
                        <div className="col-6">
                            <div className="mb-4">
                                <SelectOrganizer/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mb-4">
                                {showcaseType !== 'Onchain' ? <SelectEvent/> : <SelectOnchainEvent/>}
                            </div>
                        </div>
                    </div>
                    {showcaseType !== 'Onchain' ?
                        <div className="row">
                            <div className="col-6">
                                <div className="mb-4">
                                    <SelectTicketLevel/>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                <div className="modal-footer justify-content-end">
                    <ButtonElement
                        className={'flex-grow-1 flex-lg-grow-0'}
                        onClick={editHandler}
                        disabled={!eventId}
                    >{t('button.link')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalAddEventToShowcase
